import React from 'react';
import api from '../services/api';
import { Col, Row } from 'reactstrap';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import Signups from './Console/signups';
import Premium from './Console/premium';
import Boosts from './Console/boosts';
import Ads from './Console/ads';
import News from './Console/news';
import Shared from './Console/shared';
import SharedInfo from './Console/shared_info';
import Deals from './Console/deals';
import Leads from './Console/leads';
import Reports from './Console/reports';
import Admin from './Console/admin';
import UserIntegrations from './Console/integrations';
import Concierge from './Console/concierge';
import ConciergeLinks from './Console/concierge_links';
import Users from './Console/users';
import HeaderPage from './Console/header';
import {ADMIN_ROLES} from "../constants/userTypes";
import UserDealStatuses from "./Console/UserDealStatuses/user_deal_statuses";
import {withRouter} from "../containers/withRouter/withRouter";

export const USER_TYPE_LABEL = {
    1: 'Buyer',
    2: 'Seller',
    3: 'M&A Advisor',
    4: 'Provider'
};

class Console extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'index',
            paramId: null,
            role: null,
            gurus: {
                brokersCountResult: [{}],
                buyersCountResult: [{}],
                gurusCountResult: [{}],
                monthCountResult: [{}],
                providerCountResult: [{}],
                sellersCountResult: [{}],
                weekCountResult: [{}],

                messages30daysResult: [{}],
                messages60daysResult: [{}],

                totalListingsResult: [{}],
                nativeDealsResult: [{}],
                sharedDealsResult: [{}],
            },
        };
    }

    componentDidMount() {
        this.init(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.init(nextProps);
    }

    init = async props => {
        const { match: { params } } = props;

        if(params.tab) { 
            this.setState({ activeTab: params.tab, paramId: params.id });
        } else {
            this.setState({ activeTab: 'index' });
        }

        if(!localStorage.getItem('token')) {
            this.props.history.push('/');
        }

        await api.get('user/header', null, true).then(user => {
            this.setState({ role: user.admin_id });

            if(user.admin_id === ADMIN_ROLES.NotAdmin) {
                window.location.href = `https://biznexus.com/404?redirect_url=${window.location.href}`;
            }

            if(user.admin_id === ADMIN_ROLES.SuperAdmin) {
                api.get('console/users', null, true).then(gurus => {
                    this.setState({ gurus });
                });
            }
        });

        
    }

    renderTab = tab => {
        switch(tab) {
            case 'signups': return <Signups />
            case 'subscriptions': return <Premium />
            case 'boosts': return <Boosts />
            case 'ads': return <Ads />
            case 'news': return <News />
            case 'deals': return <Deals />
            case 'leads': return <Leads />
            case 'deal-statuses': return <UserDealStatuses />
            case 'reports': return <Reports />
            case 'admin': return <Admin />
            case 'user-integrations': return <UserIntegrations />
            case 'concierge': return <Concierge />
            case 'users': return <Users />
            case 'concierge-links': return <ConciergeLinks id={this.state.paramId} />
            case 'header': return <HeaderPage />
            case 'shared':
                if(this.state.paramId) {
                    return <SharedInfo id={this.state.paramId} />
                } else {
                    return <Shared />
                }
            default: return this.indexPage();
        }
    }

    indexPage = () => {
        const isAdmin = this.state.role === ADMIN_ROLES.SuperAdmin;

        return (
            <div className="container console-wrapper" style={{ width: '100%', maxWidth: '100%' }}>
                <h1>Console</h1>
                {isAdmin && <Row>
                    <Col sm="2">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                            <div className="col">
                                <h6 className="text-uppercase text-muted mb-2">
                                    Total Users
                                </h6>
                                <span className="h2 mb-0">
                                    {this.state.gurus.gurusCountResult[0].count}
                                </span>

                                
                            </div>
                            <div className="col-auto">  
                                <span className="h2 fe fe-dollar-sign text-muted mb-0"/>
                            </div>
                            </div> 
                        </div>
                    </div>
                    </Col>
                    <Col sm="2">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                            <div className="col">
                                <h6 className="text-uppercase text-muted mb-2">
                                    Buyers
                                </h6>
                                <span className="h2 mb-0">
                                    {this.state.gurus.buyersCountResult[0].count}
                                </span>

                                
                            </div>
                            <div className="col-auto">  
                                <span className="h2 fe fe-dollar-sign text-muted mb-0"/>
                            </div>
                            </div> 
                        </div>
                    </div>
                    </Col>
                    <Col sm="2">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                            <div className="col">
                                <h6 className="text-uppercase text-muted mb-2">
                                    Sellers
                                </h6>
                                <span className="h2 mb-0">
                                    {this.state.gurus.sellersCountResult[0].count}
                                </span>

                                
                            </div>
                            <div className="col-auto">  
                                <span className="h2 fe fe-dollar-sign text-muted mb-0"/>
                            </div>
                            </div> 
                        </div>
                    </div>
                    </Col>
                    <Col sm="2">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                            <div className="col">
                                <h6 className="text-uppercase text-muted mb-2">
                                    Brokers
                                </h6>
                                <span className="h2 mb-0">
                                    {this.state.gurus.brokersCountResult[0].count}
                                </span>
                            </div>
                            <div className="col-auto">  
                                <span className="h2 fe fe-dollar-sign text-muted mb-0"/>
                            </div>
                            </div> 
                        </div>
                    </div>
                    </Col>
                    <Col sm="2">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                            <div className="col">
                                <h6 className="text-uppercase text-muted mb-2">
                                    Providers
                                </h6>
                                <span className="h2 mb-0">
                                    {this.state.gurus.providerCountResult[0].count}
                                </span>

                            </div>
                            <div className="col-auto">  
                                <span className="h2 fe fe-dollar-sign text-muted mb-0"/>
                            </div>
                            </div> 
                        </div>
                    </div>
                    </Col>
                    <Col sm="2">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                            <div className="col">
                                <h6 className="text-uppercase text-muted mb-2">
                                    Signups last 7 days
                                </h6>
                                <span className="h2 mb-0">
                                {this.state.gurus.weekCountResult[0].count}
                                </span>
                            </div>
                            <div className="col-auto">  
                                <span className="h2 fe fe-dollar-sign text-muted mb-0"/>
                            </div>
                            </div> 
                        </div>
                    </div>
                    </Col>
                </Row>}
                <br />
                {isAdmin && <Row>
                    <Col sm="2">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                            <div className="col">
                                <h6 className="text-uppercase text-muted mb-2">
                                Signups last 30 days
                                </h6>
                                <span className="h2 mb-0">
                                {this.state.gurus.monthCountResult[0].count}
                                </span>
                            </div>
                            <div className="col-auto">  
                                <span className="h2 fe fe-dollar-sign text-muted mb-0"/>
                            </div>
                            </div> 
                        </div>
                    </div>
                    </Col>
                    <Col sm="2">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                            <div className="col">
                                <h6 className="text-uppercase text-muted mb-2">
                                    Messages last 30 days
                                </h6>
                                <span className="h2 mb-0">
                                    {this.state.gurus.messages30daysResult[0].count}
                                </span>

                               
                            </div>
                            <div className="col-auto">  
                                <span className="h2 fe fe-dollar-sign text-muted mb-0"/>
                            </div>
                            </div> 
                        </div>
                    </div>
                    </Col>
                    <Col sm="2">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                            <div className="col">
                                <h6 className="text-uppercase text-muted mb-2">
                                    Messages last 60 days
                                </h6>
                                <span className="h2 mb-0">
                                    {this.state.gurus.messages60daysResult[0].count}
                                </span>
                            </div>
                            <div className="col-auto">  
                                <span className="h2 fe fe-dollar-sign text-muted mb-0"/>
                            </div>
                            </div> 
                        </div>
                    </div>
                    </Col>
                    <Col sm="2">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                            <div className="col">
                                <h6 className="text-uppercase text-muted mb-2">
                                    Total Listings
                                </h6>
                                <span className="h2 mb-0">
                                    {this.state.gurus.totalListingsResult[0].count}
                                </span>

                                
                            </div>
                            <div className="col-auto">  
                                <span className="h2 fe fe-dollar-sign text-muted mb-0"/>
                            </div>
                            </div> 
                        </div>
                    </div>
                    </Col>
                    <Col sm="2">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                            <div className="col">
                                <h6 className="text-uppercase text-muted mb-2">
                                    Native Deals
                                </h6>
                                <span className="h2 mb-0">
                                    {this.state.gurus.nativeDealsResult[0].count}
                                </span>

                            </div>
                            <div className="col-auto">  
                                <span className="h2 fe fe-dollar-sign text-muted mb-0"/>
                            </div>
                            </div> 
                        </div>
                    </div>
                    </Col>
                    <Col sm="2">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                            <div className="col">
                                <h6 className="text-uppercase text-muted mb-2">
                                    Shared Deals
                                </h6>
                                <span className="h2 mb-0">
                                    {this.state.gurus.sharedDealsResult[0].count}
                                </span>

                            </div>
                            <div className="col-auto">  
                                <span className="h2 fe fe-dollar-sign text-muted mb-0"/>
                            </div>
                            </div> 
                        </div>
                    </div>
                    </Col>
                </Row>}
                <br /><br />
                <Row>
                    <Col sm="3">
                        <Link to="/console/signups" style={{ height: 150, borderRadius: 1, fontSize: 28 }} className="btn btn-info w-100"><i className="fas fa-users"/>  Signups</Link>
                    </Col>
                    <Col sm="3">
                        <Link to="/console/users" style={{ height: 150, borderRadius: 1, fontSize: 28 }} className="btn btn-info w-100"><i
                            className="fa-regular fa-address-card"></i> Users</Link>
                    </Col>
                    <Col sm="3">
                        <Link to="/console/subscriptions" style={{ height: 150, borderRadius: 1, fontSize: 28 }} className="btn btn-info w-100"><i className="fas fa-money-bill-wave"/>  Subscriptions</Link>
                    </Col>
                    <Col sm="3">
                        <Link to="/console/boosts" style={{ height: 150, borderRadius: 1, fontSize: 28 }} className="btn btn-info w-100"><i className="fas fa-bolt"/>  Boosts</Link>
                    </Col>

                </Row>
                <br /><br />
                <Row>
                    {isAdmin && <Col sm="3">
                        <Link to="/console/shared" style={{ height: 150, borderRadius: 1, fontSize: 28 }} className="btn btn-info w-100"><i className="fas fa-user-secret"/>  Shared Deals</Link>
                    </Col>}
                    <Col sm="3">
                        <Link to="/console/ads" style={{ height: 150, borderRadius: 1, fontSize: 28 }} className="btn btn-info w-100"><i className="fas fa-ad"/>  Advertise</Link>
                    </Col>

                    <Col sm="3">
                        <Link to="/console/news" style={{ height: 150, borderRadius: 1, fontSize: 28 }} className="btn btn-info w-100"><i className="fas fa-rss"/>  News</Link>
                    </Col>

                    <Col sm="3">
                        <Link to="/console/deals" style={{ height: 150, borderRadius: 1, fontSize: 28 }} className="btn btn-info w-100"><i className="far fa-bell"/>  Proprietary Deals</Link>
                    </Col>


                    
                </Row>
                <br /><br />
                <Row>
                    {isAdmin && <Col sm="3">
                        <Link to="/console/reports" style={{ height: 150, borderRadius: 1, fontSize: 28 }} className="btn btn-info w-100"><i className="fa fa-file-text"/> Reports</Link>
                    </Col>}
                    {isAdmin && <Col sm="3">
                        <Link to="/console/admin" style={{ height: 150, borderRadius: 1, fontSize: 28 }} className="btn btn-info w-100"><i
                            className="fa-solid fa-lock"/> Admin</Link>
                    </Col>}
                    <Col sm="3">
                        <Link to="/console/user-integrations" style={{ height: 150, borderRadius: 1, fontSize: 28 }} className="btn btn-info w-100"><i className="fa fa-code"/> User Integrations</Link>
                    </Col>
                    {isAdmin && <Col sm="3">
                        <Link to="/console/concierge" style={{ height: 150, borderRadius: 1, fontSize: 28 }} className="btn btn-info w-100"><i
                            className="fa-solid fa-bell-concierge"></i> Concierge</Link>
                    </Col>}
                </Row>
                <br /><br />

                <Row>
                    {isAdmin && <Col sm="3">
                        <Link to="/console/leads" style={{ height: 150, borderRadius: 1, fontSize: 28 }} className="btn btn-info w-100"><i className="fas fa-users"/>  Suggested buyers database</Link>
                    </Col>}
                    <Col sm="3">
                        <Link to="/console/header" style={{ height: 150, borderRadius: 1, fontSize: 28 }} className="btn btn-info w-100"><i
                            className="fa-solid fa-heading"></i> Header</Link>
                    </Col>
                    {isAdmin && <Col sm="3">
                        <Link to="/console/deal-statuses" style={{ height: 150, borderRadius: 1, fontSize: 28 }} className="btn btn-info w-100"><i
                            className="fa-regular fa-circle-check"/>  User Deal Statuses</Link>
                    </Col>}
                </Row>

            </div>
        )
    }
   

    render() {

        return (
            <div>
                <Header />

                <section className="profile-bg" style={{ backgroundColor: '#f5f6f7', minHeight: 800 }}>
                    <div className="profile-form mt-4 mb-3">

                        <div className="container" style={{ width: '100%', maxWidth: '100%' }}>
                            {this.renderTab(this.state.activeTab)}
                        </div>
                    </div>
                </section>      
                <Footer />
            </div>
        )
    }
}

export default withRouter(Console);
