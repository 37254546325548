import moment from 'moment';
import React from 'react';
import Geosuggest from '@ubilabs/react-geosuggest';
import ScrollBooster from 'scrollbooster';
import Select from 'react-select';
import {
    Button,
    Col,
    Collapse,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    UncontrolledTooltip,
    TabContent, TabPane, Nav, NavItem, NavLink, Table, DropdownItem, UncontrolledDropdown, InputGroupText
} from 'reactstrap';
import _ from 'lodash';
import classnames from 'classnames';
import {
    LinkedinShareButton,
    LinkedinIcon,
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon
} from 'react-share';
import {uploadFile} from '../services/file';
import {cleanNumericString} from '../services/intl';
import {getImageUrl} from './util';
import api from '../services/api';
import FeaturedModal from './FeaturedListing/modal';
import EmbedListingsEditProfile from './EmbedListingsEditProfile';
import {trackEvent} from './util';
import CompletedTransactions from './CompletedTransactions';
import MyLeads from './MyLeads';
import IndustryFilter from "./cards/SearchFilters/IndustryFilter";
import {USER_TYPE} from "../constants/userTypes";
import SuggestedBuyers from "./SuggestedBuyers/SuggestedBuyers";

import Pagination from "react-js-pagination";
import {AuthContext} from "../contexts/auth";
import {withRouter} from "../containers/withRouter/withRouter";
import ImageEditor from "./ImageEditor";
import Dropzone from "./Dropzone";

var ListingcategoryRemoveClicked = false;

const currentYear = new Date().getFullYear()
const years = [currentYear - 1, currentYear - 2, currentYear - 3]

class SaleListing extends React.Component {
    constructor(props) {
        super(props);
        const searchParams = new URLSearchParams(props.history.location.search)

        const params = (new URL(document.location)).searchParams;
        const paramsType = params.get('type')

        this.state = {
            fields: [],
            brokerIndustry: [],
            errors: {},
            success: {},
            eventModal: paramsType === 'boosted_listing',
            eventHeader: paramsType === 'boosted_listing' ? 'Thank You!' : '',
            eventMessage: paramsType === 'boosted_listing' ? 'Your deal was successfully boosted.' : '',
            updateimage: '0',
            currentPage: 1,
            totalCount: 0,
            dealPerPage: 10,
            dealLoading: true,
            listingimg: null,
            customMessage: '',
            listingimage: '',
            Listingmodal: false,
            editId: '',
            listinglist: [],
            locationvalues: [],
            location: '',
            latitude: '',
            longitude: '',
            city: '',
            state: '',
            establishedselectedOption: '',
            establishedyear: [],
            location_visibility: 30, // ShowCounty
            category_date: moment(),
            listingcollapse: false,
            listingToggle: 'none',
            Deletelistmodal: false,
            financialcollapse: false,
            businesscollapse: false,
            financialclass: '',
            businessclass: '',
            faqcollapse: false,
            faqclass: '',
            itemID: '',
            secondlevelmax: '',
            selectlabel: 1,
            ListingdropdownCategory: false,
            industrylistListing: [],
            activeTab: 1,
            active: [],
            inactive: [],
            plans: [],
            boostModalOpen: false,
            boostModalId: null,
            historical: [{
                year: years[0],
                cashflow: null,
                ebitda: null,
                revenue: null
            }, {
                year: years[1],
                cashflow: null,
                ebitda: null,
                revenue: null
            }, {
                year: years[2],
                cashflow: null,
                ebitda: null,
                revenue: null
            }],
            ttm: {
                year: 'ttm',
                cashflow: null,
                ebitda: null,
                revenue: null
            },
            faq: [{
                q: '',
                a: '',
            }],
            suggestedBuyers: true,
            deal_on_market: null,
            seller_finance_deal: false,
            privateDealModal: false,
            privateSuccessModal: false,
            privateDeals: [],
            privateDealModalOk: false,
            premium: false,
            user_type_id: null,
            fsbo: [],
            header: {},
            want_to_sell: null,
            needApproveModal: false,
            activeLeftTab: null,
            archivedDeals: [],
            created_transaction_id: null,
            created_transaction_name: '',
            triggerListingModal: searchParams.get('createListing'),
            privateTeaser: false,
            professionlist: [],
            selectedCompletedOption: {value: 16, label: 'Individual Buyer'},
            avatarNotFound: false,
            viewMode: 'list',
            filterSearch: null,
        };
        this.Listingtoggle = this.Listingtoggle.bind(this);
        this.listingcollapsestoggle = this.listingcollapsestoggle.bind(this);
        this.Deletelisttoggle = this.Deletelisttoggle.bind(this);
        this.arrowtoggleListing = this.arrowtoggleListing.bind(this);
        this.financialtoggle = this.financialtoggle.bind(this);
        this.businesstoggle = this.businesstoggle.bind(this);
        this.ListingCategorytoggle = this.ListingCategorytoggle.bind(this);
    }

    static contextType = AuthContext

    componentWillReceiveProps(nextProps) {
        this.init(nextProps);
    }

    init = props => {
        const {match} = props;

        if (match && match.params.subtab) {
            const event = match.params.subtab;
            if (event === 'suggested_buyers') {
                this.setState({activeLeftTab: 'Leads'});
            }

            if (event === 'suggested_brokers') {
                this.setState({activeLeftTab: 'Brokers'});
            }

            if (event === 'archive') {
                this.setState({activeLeftTab: 'ArchivedListings'});
            }

            if (event === 'completed') {
                this.setState({activeLeftTab: 'CompletedTransactions'});
            }

        } else {
            this.setState({activeLeftTab: null});
        }
    }


    Listingtoggle = () => {

        if (this.state.header.status === '0') {
            this.setState({needApproveModal: true});
            return;
        }

        let categorydata = this.state.industrylistListing;
        // eslint-disable-next-line
        this.state.industrylistListing.map((category, i) => {
            categorydata[i].checked = false;
            categorydata[i].partialed = false;
            // eslint-disable-next-line
            categorydata[i].children.map((lists, j) => {
                categorydata[i].children[j].checked = false;
            });
        });
        this.setState({
            Listingmodal: !this.state.Listingmodal,
            editId: '',
            fields: [],
            locationvalues: [],
            location: '',
            latitude: '',
            longitude: '',
            errors: {},
            listingimg: null,
            listingimage: '',
            secondlevelmax: '',
            selectlabel: 1,
            success: '',
            industrylistTransaction: categorydata,
            brokerIndustry: [],
            customMessage: '',
            historical: [{
                year: years[0],
                cashflow: null,
                ebitda: null,
                revenue: null
            }, {
                year: years[1],
                cashflow: null,
                ebitda: null,
                revenue: null
            }, {
                year: years[2],
                cashflow: null,
                ebitda: null,
                revenue: null
            }],
            ttm: {
                year: 'ttm',
                cashflow: null,
                ebitda: null,
                revenue: null
            },
            faq: [{
                q: '',
                a: '',
            }],
        });
        window.setTimeout(() => {
            if (document.getElementById('listingtitle') !== null) {
                document.getElementById('listingtitle').focus();
            }
        }, 500);
    }

    listingcollapsestoggle(e) {
        e.preventDefault();
        this.setState({listingcollapse: !this.state.listingcollapse});
        if (this.state.listingToggle === '') {
            this.setState({listingToggle: 'none'});
        } else {
            this.setState({listingToggle: ''});
        }
    }

    financialtoggle = () => {
        this.setState({financialcollapse: !this.state.financialcollapse});
        if (this.state.financialclass === '') {
            this.setState({financialclass: 'active'});
        } else {
            this.setState({financialclass: ''});
        }
    }

    businesstoggle() {
        this.setState({businesscollapse: !this.state.businesscollapse});
        if (this.state.businessclass === '') {
            this.setState({businessclass: 'active'});
        } else {
            this.setState({businessclass: ''});
        }
    }

    faqtoggle() {
        this.setState({faqcollapse: !this.state.faqcollapse});
        if (this.state.faqclass === '') {
            this.setState({faqclass: 'active'});
        } else {
            this.setState({faqclass: ''});
        }
    }


    async getFeaturedList() {
        await api.get('promote/list', null, true)
            .then(res => {
                this.setState({active: res});
            });

        await api.get('promote/plans', null, true)
            .then(res => {
                this.setState({plans: res});
            });

        await api.get('promote/list_inactive', null, true)
            .then(res => {
                this.setState({inactive: res});
            });
    }

    unsubscribe = async id => {
        api.post('promote/unsubscribe', {id}, true)
            .then(() => {
                setTimeout(() => {
                    this.getFeaturedList()
                }, 300);
            });
    }

    getAmount = planId => {
        const plan = _.find(this.state.plans, {id: planId});
        if (plan) {
            return '$' + plan.amount / 100;
        }

        return null;
    }

    componentDidMount() {
        this.getFeaturedList();
        let establishedyeararray = [];
        for (let i = 1950; i <= moment().year(); i++) {
            let temp = {
                "value": i,
                "label": i
            };
            establishedyeararray.push(temp);
        }

        this.setState({establishedyear: establishedyeararray.reverse()});

        const viewMode = localStorage.getItem('viewModeDeal');

        if (viewMode) {
            this.setState({viewMode});
        }

        this.getListingList(() => this.init(this.props));
        this.getPrivateListingsList();
        this.getArchivedDeal();

        api.get('user/userProfessionlist', null, false)
            .then(res => {
                let professionlistarray = [];
                // eslint-disable-next-line
                res.map((choice, i) => {
                    if (choice.status === '1') {
                        let temp = {
                            "value": choice.id,
                            "label": choice.profession_value
                        };
                        professionlistarray.push(temp);
                    }
                });
                this.setState({professionlist: professionlistarray});
            });

        fetch(process.env.REACT_APP_API_ROOT + 'industry/legacy', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
        }).then(res => res.json())
            .then(
                (res) => {
                    //console.log(res.list);
                    this.setState({industrylistListing: res});
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        //error
                    });
                }
            )
    }

    handleChangeCompletedSelect = (selectedCompletedOption) => {
        this.setState({selectedCompletedOption});
    }

    ListingCategorytoggle = () => {
        if (ListingcategoryRemoveClicked) {
            ListingcategoryRemoveClicked = !ListingcategoryRemoveClicked
            return;
        }
        this.setState({ListingdropdownCategory: !this.state.ListingdropdownCategory});
    }
    arrowtoggleListing = (e) => {
        let id = e.target.id;
        let splitValue = id.split("_");
        let splitid = splitValue[1];
        let categorydata = this.state.industrylistListing;
        if (categorydata[splitid].toggle === undefined) {
            categorydata[splitid].toggle = true;
        } else {
            if (categorydata[splitid].toggle === false) {
                categorydata[splitid].toggle = true;
            } else {
                categorydata[splitid].toggle = false;
            }
        }
        this.setState({industrylistListing: categorydata});
    }

    removeCategoryClick(e) {


        let categorydata = this.state.industrylistListing;
        let id = e;
        let splitValue = id.split("_");
        let splitid = splitValue[0];
        let type = splitValue[1];
        if (type === 'parentselected') {
            categorydata[splitid].checked = false;
            // eslint-disable-next-line
            categorydata[splitid].children.map((lists, i) => {
                categorydata[splitid].children[i].checked = false;
            });
        } else {
            let child_id = splitValue[2];
            categorydata[splitid].children[child_id].checked = false;
        }
        this.setState({industrylistListing: categorydata});
    }

    getUpdatedIndustryList = (item, checked) => {
        return this.state.industrylistListing.map(i =>
            i.value === item.value
                ? {...i, checked}
                : i.children && i.children.length
                    ? {...i, children: i.children.map(ic => ic.value === item.value ? {...ic, checked} : ic)}
                    : i
        )
    }
    getParentAndChildCounts = (newItems) => {
        let parentCount = 0;
        let childCount = 0;

        // eslint-disable-next-line
        newItems.forEach((industrySelected, i) => {
            if (industrySelected.children && industrySelected.children.length) {
                parentCount++;
            } else {
                childCount++;
            }
        });
        return {parentCount, childCount}
    }

    onRemoveBrokerIndustry = item => {
        const brokerIndustry = _.reject(this.state.brokerIndustry, bi => bi.value === item.value);
        const industrylistListing = this.getUpdatedIndustryList(item, false)
        const {parentCount, childCount} = this.getParentAndChildCounts(brokerIndustry)

        if (parentCount === 1 && childCount === 1) {
            this.setState({secondlevelmax: 1, ListingdropdownCategory: false});
        } else {
            this.setState({secondlevelmax: '', selectlabel: 0});
        }
        this.setState({
            brokerIndustry,
            industrylistListing
        });
    }

    onUpdateBrokerIndustry = (items = []) => {
        const selectedItem = items.find(item => !this.state.brokerIndustry.some(bi => bi.value === item.value))
        if (!selectedItem) {
            const deselectedItem = this.state.brokerIndustry.find(bi => !items.some(item => bi.value === item.value))
            this.onRemoveBrokerIndustry(deselectedItem)
            return
        }
        const newIndustryList = this.getUpdatedIndustryList(selectedItem, true)
        const {parentCount, childCount} = this.getParentAndChildCounts(items)

        if (parentCount < 3 && childCount < 3) {
            if (parentCount === 1 && childCount === 1) {
                this.setState({secondlevelmax: 1, ListingdropdownCategory: false});
            } else {
                this.setState({secondlevelmax: '', selectlabel: 0});
            }
        } else {
            this.setState({secondlevelmax: 1, ListingdropdownCategory: false});
            return
        }
        this.setState({
            brokerIndustry: items,
            industrylistListing: newIndustryList
        });
    }

    getListingList = async (cb) => {
        this.setState({
            dealLoading: true
        })
        await fetch(process.env.REACT_APP_API_ROOT + `transaction?recommendation=1&brokerRecommendation=1&page=${this.state.currentPage}&limit=${this.state.dealPerPage}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token'),
            },
        })
            .then(r => r.json())
            .then(r => {
                const preparedList = r.list.map(l => ({
                    ...l,
                    buyers_to_recommend_free: _.shuffle(_.uniqBy(l.buyers_to_recommend, 'id').slice(0, 10)).slice(0, 3),
                    brokers_to_recommend_free: _.shuffle(_.uniqBy(l.brokers_to_recommend, 'id').slice(0, 10)).slice(0, 3),
                }))
                this.setState({
                    listinglist: preparedList,
                    totalCount: r.count,
                    listinglistLoaded: true,
                    dealLoading: false,
                }, () => {
                    if (cb) {
                        cb()
                    }
                });
            })

        const viewport = document.querySelectorAll('.drag-viewport');
        const content = document.querySelectorAll('.drag-content');
        if (viewport[0] && content[0]) {
            for (let i = 0; i < viewport.length; i++) {
                new ScrollBooster({
                    viewport: viewport[i],
                    content: content[i],
                    direction: "horizontal",
                    scrollMode: "native",
                    emulateScroll: true,
                    // bounce: false,
                    onUpdate: (state) => {
                        viewport.scrollLeft = state.position.x;
                    }
                });
            }

        }
    }

    getArchivedDeal = () => {
        // get archived listings list
        fetch(process.env.REACT_APP_API_ROOT + 'transaction/archived_deals', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token'),
            },
        })
            .then(r => r.json())
            .then(r => this.setState({archivedDeals: r}));
    }

    getPrivateListingsList = () => {
        fetch(process.env.REACT_APP_API_ROOT + 'transaction?completed=2', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token'),
            },
        })
            .then(r => r.json())
            .then(r => this.setState({privateDeals: r.list, privateDealsCount: r.count}));
    }
    handleEditListingClick = async (e) => {
        // e.preventDefault();
        if (e.target.id !== '') {
            document.getElementById('loaderlistingview').setAttribute("style", "display: block;");
            await fetch(process.env.REACT_APP_API_ROOT + 'transaction/' + e.target.id, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    'x-access-token': localStorage.getItem('token'),
                },
            })
                .then(r => r.json())
                .then((res) => {
                        document.getElementById('loaderlistingview').setAttribute("style", "display: none;");
                        this.Listingtoggle();


                        let fields = this.state.fields.slice();
                        fields["listingtitle"] = res.name;
                        fields["referencenumber"] = res.referencenumber;
                        fields["companyname"] = res.company_name;
                        fields["revenue"] = cleanNumericString(res.revenue);
                        fields["price"] = cleanNumericString(res.price) === '0' ? '' : cleanNumericString(res.price);
                        fields["ebitda"] = cleanNumericString(res.ebitda);
                        fields["cashflow"] = cleanNumericString(res.cashflow);
                        fields["ppe"] = cleanNumericString(res.ppe);
                        fields["real_estate"] = cleanNumericString(res.real_estate);
                        fields["inventory"] = cleanNumericString(res.inventory);
                        fields["description"] = res.description;
                        fields["reasonselling"] = res.reason_for_selling;
                        fields["facilities"] = res.facilities;
                        fields["financing"] = res.financing;
                        fields["competition"] = res.competition;
                        fields["growthopportunity"] = res.growth_opportunity;
                        fields["ongoingsupport"] = res.ongoing_support;
                        fields["video"] = res.video;
                        fields['seller_financing'] = res.seller_financing;
                        fields['leased_or_owned'] = res.leased_or_owned;
                        fields['included_in_asking_price'] = res.included_in_asking_price;
                        fields['debt_payments'] = res.debt_payments;
                        fields['asset_sale'] = res.asset_sale;
                        fields['competitive_advantages'] = res.competitive_advantages;
                        fields['nda'] = res.nda;
                        fields['teaser'] = res.teaser;
                        fields['show_price'] = res.show_price;
                        fields['completed'] = res.completed;
                        fields['keywords_search'] = res.keywords_search;
                        fields['target_markets'] = res.target_markets;
                        let dataArea = [];
                        let temp = '';
                        if (res.location_address !== "") {
                            temp = {
                                "description": res.location_address,
                                "label": res.location_address,
                                "latitude": res.latitude,
                                "longitude": res.longitude

                            };
                            dataArea.push(temp);
                        }

                        fetch(process.env.REACT_APP_API_ROOT + 'transaction/' + res.id + '/legacyindustries', {
                            method: 'GET',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                "Access-Control-Allow-Origin": "*",
                                'x-access-token': localStorage.getItem('token'),
                            },
                        })
                            .then(r => r.json())
                            .then((legacy_industries) => {
                                const selectedIndustries = []
                                if (res.industries && res.industries.length) {
                                    legacy_industries.forEach(industry => {
                                        res.industries?.forEach(selectedIndustry => {
                                            if (selectedIndustry.industry_id === industry.value) {
                                                selectedIndustries.push(industry)
                                            }
                                        })
                                        if (industry.children && industry.children.length) {
                                            industry.children.forEach(subIndustry => {
                                                res.industries?.forEach(selectedIndustry => {
                                                    if (selectedIndustry.industry_id === subIndustry.value) {
                                                        selectedIndustries.push(subIndustry)
                                                    }
                                                })
                                            })
                                        }
                                    })
                                }
                                this.setState({
                                    editId: res.id,
                                    fields,
                                    listingimage: res.image,
                                    industrylistListing: legacy_industries,
                                    brokerIndustry: selectedIndustries,
                                    selectlabel: res.industries.length === 0,
                                    establishedselectedOption: (res.date === null) ? this.state.establishedselectedOption : {label: res.date, value: res.date},
                                    locationvalues: dataArea,
                                    location_visibility: res.location_visibility,
                                    location: res.location_address,
                                    latitude: res.latitude,
                                    longitude: res.longitude,
                                    want_to_sell: res.want_to_sell,
                                });

                                this.setState({
                                    faq: [{
                                        q: '',
                                        a: '',
                                    }],
                                    historical: [{
                                        year: years[0],
                                        cashflow: null,
                                        ebitda: null,
                                        revenue: null
                                    }, {
                                        year: years[1],
                                        cashflow: null,
                                        ebitda: null,
                                        revenue: null
                                    }, {
                                        year: years[2],
                                        cashflow: null,
                                        ebitda: null,
                                        revenue: null
                                    }],
                                });

                                if (res.faq) {
                                    const f = JSON.parse(res.faq);
                                    if (f.length > 0) {
                                        this.setState({
                                            faq: f,
                                        });
                                    }
                                }

                                if (res.historical) {
                                    const h = JSON.parse(res.historical);
                                    const ttm = h.filter(d => d.year === 'ttm')

                                    const his = h.filter(d => d.year !== 'ttm');

                                    const y21 = his.find(d => d.year === years[0]) || {};
                                    const y20 = his.find(d => d.year === years[1]) || {};
                                    const y19 = his.find(d => d.year === years[2]) || {};
                                    if (his.length > 0) {
                                        this.setState({
                                            historical: [{
                                                year: years[0],
                                                cashflow: y21.cashflow,
                                                ebitda: y21.ebitda,
                                                revenue: y21.revenue
                                            }, {
                                                year: years[1],
                                                cashflow: y20.cashflow,
                                                ebitda: y20.ebitda,
                                                revenue: y20.revenue
                                            }, {
                                                year: years[2],
                                                cashflow: y19.cashflow,
                                                ebitda: y19.ebitda,
                                                revenue: y19.revenue
                                            }],
                                        });
                                    }


                                    if (ttm.length === 1) {
                                        this.setState({
                                            ttm: ttm[0]
                                        });
                                    } else {
                                        this.setState({
                                            ttm: {
                                                year: 'ttm',
                                                cashflow: '',
                                                ebitda: '',
                                                revenue: ''
                                            }
                                        });
                                    }
                                } else {

                                    this.setState({
                                        historical: [{
                                            year: years[0],
                                            cashflow: null,
                                            ebitda: null,
                                            revenue: null
                                        }, {
                                            year: years[1],
                                            cashflow: null,
                                            ebitda: null,
                                            revenue: null
                                        }, {
                                            year: years[2],
                                            cashflow: null,
                                            ebitda: null,
                                            revenue: null
                                        }],
                                        ttm: {
                                            year: 'ttm',
                                            cashflow: '',
                                            ebitda: '',
                                            revenue: ''
                                        }
                                    });
                                }
                            });
                    },
                    (error) => {
                        this.setState({
                            //error
                        });
                    }
                )
        }

    }
    Deletelisttoggle = (e) => {
        e.preventDefault();
        this.setState({Deletelistmodal: !this.state.Deletelistmodal, itemID: e.target.id});
    }
    handleRemoveListingClick = async (e) => {
        this.setState({Deletelistmodal: !this.state.Deletelistmodal});
        if (e.target.getAttribute("data-itemID") !== '') {
            this.setState({
                dealLoading: true
            })
            await fetch(process.env.REACT_APP_API_ROOT + `transaction/${e.target.getAttribute("data-itemID")}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    'x-access-token': localStorage.getItem('token'),
                },
            }).then((res) => {
                if (res.status < 200 || res.status >= 300) {
                    //console.log("Error 401");
                    //console.log(res);
                    if (res.status === 401) {
                        this.props.history.push('/logout');
                    } else {
                        return res.json().then(err => {
                            throw err;
                        });
                    }
                } else {
                    return res
                }
            }).then(res => {
                this.setState(prevState => ({
                    ...prevState,
                    currentPage: 1
                }), () => this.getListingList())
            })
        }
    }

    handlelistingValidation() {
        let fields = this.state.fields;
        let errors = {};
        let customMessage = '';
        let formIsValid = true;

        if (fields.price && fields.price.length > 13) {
            formIsValid = false;
            errors["price"] = "Invalid value";
        }

        if (fields.revenue && fields.revenue.length > 13) {
            formIsValid = false;
            errors["revenue"] = "Invalid value";
        }

        if (fields.cashflow && fields.cashflow.length > 13) {
            formIsValid = false;
            errors["cashflow"] = "Invalid value";
        }

        if (fields.ebitda && fields.ebitda.length > 13) {
            formIsValid = false;
            errors["ebitda"] = "Invalid value";
        }

        if (fields.inventory && fields.inventory.length > 13) {
            formIsValid = false;
            errors["inventory"] = "Invalid value";
        }

        if (fields.ppe && fields.ppe.length > 13) {
            formIsValid = false;
            errors["ppe"] = "Invalid value";
        }

        if (fields.real_estate && fields.real_estate.length > 13) {
            formIsValid = false;
            errors["real_estate"] = "Invalid value";
        }

        if (fields.debt_payments && fields.debt_payments.length > 13) {
            formIsValid = false;
            errors["debt_payments"] = "Invalid value";
        }


        //listingtitle
        if (!fields["listingtitle"]) {
            formIsValid = false;
            errors["listingtitle"] = "Please enter listing title.";
        }

        if (fields["listingtitle"] && fields["listingtitle"].length > 150) {
            formIsValid = false;
            errors["listingtitle"] = "Maximum length 150 characters";
        }

        if (!fields['description']) {
            formIsValid = false;
            errors['description'] = "Please enter description.";
        }

        const techCategoryIsSelected = !!this.state.industrylistListing.filter(c => {
            return (c.checked === true && c.label === 'Tech & Media') || (c.label === 'Tech & Media' && c.children.filter(cc => cc.checked).length > 0)
        }).length;

        // if(this.state.editId!==''){

        //     let listingimg = this.state.listingimg;

        //     if(listingimg.error!==null && !techCategoryIsSelected) {
        //         formIsValid = false;
        //         customMessage = "Please upload image.";
        //     }

        // }else{

        //     if(this.state.listingimage==='' && !techCategoryIsSelected) {
        //         formIsValid = false;
        //         customMessage = "Please upload image.";
        //     }

        // }

        let checkPlus = 0;

        // eslint-disable-next-line
        this.state.industrylistListing.map((industrySelected, i) => {
            if (industrySelected.checked === true && industrySelected.label !== "Tech & Media") {
                //console.log('In If Condition');
                checkPlus = checkPlus + 1;
            } else {
                // eslint-disable-next-line
                industrySelected.children.map((industryChildren, j) => {

                    if (industryChildren.checked === true && industryChildren.label !== "Advertising & Marketing" && industryChildren.label !== "Information Technology" && industryChildren.label !== "Internet" && industryChildren.label !== "Media") {
                        //console.log('In Else If Condition');
                        checkPlus = checkPlus + 1;
                    }

                });
            }
        });

        /*
        console.log(this.state.locationvalues.length);
        console.log(checkPlus);*/


        if (!techCategoryIsSelected && this.state.locationvalues.length === 0) {

            formIsValid = false;
            errors["location"] = "Please select location";
        }


        //category
        if (this.state.selectlabel === 1) {
            formIsValid = false;
            errors["category"] = "Please select relevant categories";
        }
        //price
        if (!fields["price"]) {
            //formIsValid = false;
            //errors["price"] = "Please enter asking price";
        }
        this.setState({errors: errors, customMessage});
        return formIsValid;
    }

    handleFormChange = (e, {name, value}) => {
        this.setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    };

    handleChangelisting(field, e) {
        // this is so stupid... we'll change it later

        if (e.target !== '' && e.target !== undefined) {
            let fields = this.state.fields;

            fields[field] = e.target.value;
            if (e.target.name === 'revenue') {
                let revenue = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = revenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'price') {
                let price = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = price.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'ebitda') {
                let ebitda = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = ebitda.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'cashflow') {
                let cashflow = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = cashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'ppe') {
                let ppe = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = ppe.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'inventory') {
                let inventory = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = inventory.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'real_estate') {
                let real_estate = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = real_estate.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'debt_payments') {
                let debt_payments = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = debt_payments.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'asset_sale') {
                fields[field] = e.target.checked;
            }
            if (e.target.name === 'show_price') {
                fields[field] = e.target.checked;
            }

            this.setState({fields});
        }
    }

    getImageDimensions = file => {
        return new Promise (function (resolved, rejected) {
            var i = new Image()
            i.onload = function(){
                resolved({w: i.width, h: i.height, src: i.src})
            };
            const reader = new FileReader();
            reader.onload = (event) => {
                i.src = event.target.result;
            };
            reader.readAsDataURL(file);
        })
    }
    onListingImageFail = failedResults => {
        this.setState({ customMessage: failedResults[0].errors[0].message });
    }

    onChangelistingimg = value => {
        const img = this.getImageDimensions(value[0])
        if (img.w >= 2100 && img.h >= 1500) {
            this.setState({
                listingimg: null,
                customMessage: 'Image dimensions must be less than 2100px width and 1500px height'
            });
        } else {
            this.setState({listingimg: value[0], customMessage: ''});
        }
    };
    handleGeosuggestChange = (location) => {
        //console.log(location);
        let dataArea = [];
        let temp = '';
        if (location !== undefined) {
            temp = {
                "location": location.label,
                "latitude": location.location.lat,
                "longitude": location.location.lng
            };
            if (this.state.locationvalues.length !== 0) {
                this.state.locationvalues.push(temp)
            } else {
                this.state.locationvalues.push(temp)
            }
            dataArea.push(temp);
            this.setState({locationvalues: dataArea});
            let addressComponent = location.gmaps.address_components;
            for (var j = 0; j < location.gmaps.address_components.length; j++) {
                if (addressComponent[j].types[0] === 'locality') {
                    //$('#ListingCity').val(addressComponent[j].long_name);
                    this.setState({city: addressComponent[j].long_name});
                }
                if (addressComponent[j].types[0] === 'administrative_area_level_1') {
                    //$('#ListingState').val(addressComponent[j].long_name);
                    this.setState({state: addressComponent[j].long_name});
                }
            }
            this.setState({location: location.label});
            this.setState({latitude: location.location.lat});
            this.setState({longitude: location.location.lng});
        } else {
            this.setState({locationvalues: [], location: '', latitude: '', longitude: '', city: '', state: ''});
        }
    }
    handleChange = (establishedselectedOption) => {
        this.setState({establishedselectedOption});
        // selectedOption can be null when the `x` (close) button is clicked
        if (establishedselectedOption) {
            // console.log(`Selected: ${establishedselectedOption.label}`);
        }
    }
    SubmitListing = async (event, privateDeal = false) => {
        event && event.preventDefault();
        if (this.handlelistingValidation()) {
            document.getElementById('loaderlisting').setAttribute("style", "display: block;");
            let fields = this.state.fields;

            const establishedselectedOption = this.state.establishedselectedOption && this.state.establishedselectedOption.value ?
                this.state.establishedselectedOption.value :
                this.state.establishedselectedOption;

            let referencenumber, companyname, ebitda, ppe, inventory, description, reasonselling, facilities, financing,
                competition, growthopportunity, ongoingsupport, revenue, cashflow = '';
            if (fields["referencenumber"] !== undefined) {
                referencenumber = fields["referencenumber"];
            } else {
                referencenumber = '';
            }
            if (fields["companyname"] !== undefined) {
                companyname = fields["companyname"];
            } else {
                companyname = '';
            }
            if (fields["ebitda"] !== undefined) {
                ebitda = fields["ebitda"];
            } else {
                ebitda = '';
            }
            if (fields["ppe"] !== undefined) {
                ppe = fields["ppe"];
            } else {
                ppe = '';
            }
            if (fields["inventory"] !== undefined) {
                inventory = fields["inventory"];
            } else {
                inventory = '';
            }
            if (fields["revenue"] !== undefined) {
                revenue = fields["revenue"];
            } else {
                revenue = '';
            }
            if (fields["cashflow"] !== undefined) {
                cashflow = fields["cashflow"];
            } else {
                cashflow = '';
            }
            if (fields["description"] !== undefined) {
                description = fields["description"];
            } else {
                description = '';
            }
            if (fields["reasonselling"] !== undefined) {
                reasonselling = fields["reasonselling"];
            } else {
                reasonselling = '';
            }
            if (fields["facilities"] !== undefined) {
                facilities = fields["facilities"];
            } else {
                facilities = '';
            }
            if (fields["financing"] !== undefined) {
                financing = fields["financing"];
            } else {
                financing = '';
            }
            if (fields["competition"] !== undefined) {
                competition = fields["competition"];
            } else {
                competition = '';
            }
            if (fields["growthopportunity"] !== undefined) {
                growthopportunity = fields["growthopportunity"];
            } else {
                growthopportunity = '';
            }
            if (fields["ongoingsupport"] !== undefined) {
                ongoingsupport = fields["ongoingsupport"];
            } else {
                ongoingsupport = '';
            }
            let categoryArrayData = [];
            // eslint-disable-next-line
            this.state.industrylistListing.map((industrySelected, i) => {
                if (industrySelected.checked === true) {
                    let categoryarray = {
                        "id": industrySelected.value,
                        "name": industrySelected.label,
                        "path": industrySelected.label + ">>All"
                    };
                    categoryArrayData.push(categoryarray);
                } else {
                    // eslint-disable-next-line
                    industrySelected.children.map((industryChildren, j) => {
                        if (industryChildren.checked === true) {
                            let categoryarray = {
                                "id": industryChildren.value,
                                "name": industryChildren.label,
                                "path": industrySelected.label + ">>" + industryChildren.label
                            };
                            categoryArrayData.push(categoryarray);
                        }
                    });
                }
            });

            let historical = this.state.historical.map(h => {
                return {
                    ...h,
                    revenue: h.revenue ? h.revenue : "0",
                    cashflow: h.cashflow ? h.cashflow : "0",
                    ebitda: h.ebitda ? h.ebitda : "0",
                }
            });

            historical.push(this.state.ttm);

            const faq = this.state.faq.filter(f => {
                return f.q && f.a;
            });

            let jsonlistingData = JSON.stringify({
                "id": this.state.editId,
                "referencenumber": referencenumber,
                "name": fields["listingtitle"],
                "company_name": companyname,
                "price": fields["price"] ? fields["price"] : '0',
                "completed": this.state.editId ? fields['completed'] : this.state.privateTeaser ? "5" : "0",
                "ebitda": ebitda,
                "ppe": ppe,
                "real_estate": fields['real_estate'],
                "inventory": inventory,
                "description": description,
                "reason_for_selling": reasonselling,
                "facilities": facilities,
                "financing": financing,
                "competition": competition,
                "growth_opportunity": growthopportunity,
                "ongoing_support": ongoingsupport,
                "date": establishedselectedOption.value,
                "location_visibility": this.state.location_visibility,
                "revenue": revenue,
                "cashflow": cashflow,
                "status": "1",
                "listing_date": this.state.category_date.format("YYYY-MM-DD"),
                "latitude": this.state.latitude,
                "longitude": this.state.longitude,
                "industries": categoryArrayData.map(c => ({industry_id: c.id})),
                "category_date": this.state.category_date.format("YYYY-MM-DD"),
                "video": fields["video"],
                'seller_financing': fields['seller_financing'],
                'leased_or_owned': fields['leased_or_owned'],
                'included_in_asking_price': fields['included_in_asking_price'],
                'debt_payments': fields['debt_payments'],
                'asset_sale': fields['asset_sale'],
                'competitive_advantages': fields['competitive_advantages'],
                'nda': fields['nda'],
                'teaser': fields['teaser'],
                'historical': JSON.stringify(historical),
                'faq': JSON.stringify(faq),
                'show_price': fields['show_price'],
                'keywords_search': fields['keywords_search'],
                'target_markets': fields['target_markets'],
                privateDeal: privateDeal,
                want_to_sell: this.state.want_to_sell,
            });

            if (!this.state.editId) {
                if (this.context.user_type_id === USER_TYPE.BROKER) {
                    trackEvent({category: 'Listing', action: 'New Broker Listing'});
                } else {
                    trackEvent({category: 'Listing', action: 'New Proprietary Listing'});
                }
            }

            await fetch(process.env.REACT_APP_API_ROOT + 'transaction/' + (this.state.editId || ""), {
                method: 'POST',
                body: jsonlistingData,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    'x-access-token': localStorage.getItem('token'),
                },
            }).then((res) => {
                if (res.status < 200 || res.status >= 300) {
                    //console.log("Error 401");
                    //console.log(res);
                    if (res.status === 401) {
                        this.props.history.push('/logout');
                    } else {
                        return res.json().then(err => {
                            throw err;
                        });
                    }
                } else {
                    return res.json()
                }
            })
                .then(
                    async (res) => {
                        if (this.state.listingimg?.name && this.state.listingimg?.name !== "") {
                            const imgUrl = this.cropperlistingimg.getImage().toDataURL()
                            await uploadFile(imgUrl, this.state.listingimg.name, this.state.listingimg.type, `transaction/${res.transaction_id}/photo`);
                        } else {
                            api.post(`transaction/uploadDefaulImage/${res.transaction_id}`, null, true);
                        }
                        window.Intercom('trackEvent', 'listing-updated', {
                            id: this.state.editId,
                            "name": fields["listingtitle"]
                        });

                        document.getElementById('loaderlisting').setAttribute("style", "display: none;");
                        let categorydata = this.state.industrylistListing;
                        // eslint-disable-next-line
                        this.state.industrylistListing.map((category, i) => {
                            categorydata[i].checked = false;
                            categorydata[i].partialed = false;
                            // eslint-disable-next-line
                            categorydata[i].children.map((lists, j) => {
                                categorydata[i].children[j].checked = false;
                            });
                        });
                        // eslint-disable-next-line
                        fields["listingtitle"] = '', fields["referencenumber"] = '', fields["companyname"] = '', fields["price"] = '', fields["ebitda"] = '', fields["ppe"] = '', fields["inventory"] = '', fields["description"] = '', fields["reasonselling"] = '', fields["facilities"] = '', fields["financing"] = '', fields["competition"] = '', fields["growthopportunity"] = '', fields["ongoingsupport"] = '', fields["revenue"] = '', fields["cashflow"] = '';
                        let success = {};
                        success["success"] = res.message;
                        this.refs.location.update('');
                        this.setState({
                            success: success, establishedselectedOption: '', editId: '', latitude: '', longitude: '',
                            listingimg: null,
                            created_transaction_name: res.name,
                            created_transaction_id: this.context.user_type_id !== 3 && this.state.editId ? null : res.transaction_id,
                            listingimage: '', secondlevelmax: '', selectlabel: 1,
                            industrylistListing: categorydata, customMessage: '', brokerIndustry: []
                        });
                        /*window.setTimeout(() => {
                            this.setState({ success: '' });
                        }, 5000);*/
                        if (document.getElementById('listingtitle') !== null) {
                            document.getElementById('listingtitle').focus();
                        }
                        document.getElementById('listingmodal').scrollIntoView(true);
                    },
                    (error) => {
                        this.setState({
                            //error
                        });
                    }
                )

            // IGOR: this is a hack for now because thumnail generation takes a second or two asynchronously
            // eventually, the thumbnail will be able to handle a missing image and retry but i'm not going to deal
            // with that in this mess.
            window.setTimeout(() => {
                this.setState(prevState => ({
                    ...prevState,
                    currentPage: 1
                }), () => this.getListingList())
            }, 2000);
        } else {
            let fields = this.state.fields;
            if (fields["price"] === undefined || fields["price"] === '') {
                this.setState({financialcollapse: true, financialclass: 'active'});
            }
            document.getElementById('listingmodal').scrollIntoView(true);
            //console.log("Form has errors.")
        }
    }

    truncString(str, max, add) {
        add = add || '...';
        return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
    };

    clearDragNCrop = () => {
        this.setState({
            listingimg: null
        })
    };

    boostModal = (listingId, listingName) => {
        this.setState({
            boostModalOpen: !this.state.boostModalOpen,
            boostModalId: listingId,
            boostModalName: listingName
        });
    }

    addNewHistoricalRow = () => {
        const {historical} = this.state;
        historical.push({
            year: historical.length ? historical[historical.length - 1].year - 1 : 2022,
            cashflow: null,
            ebitda: null,
            revenue: null
        });
        this.setState({
            historical
        });
    }

    removeHistoricalRow = i => {
        const {historical} = this.state;
        historical.splice(i, 1);
        this.setState({historical});
    }

    handleChangeHistoricYear = (e, i) => {
        const {value} = e.target;
        const {historical} = this.state;

        historical[i].year = value;
        this.setState({historical});
    }

    handleRowChange = (field, i, e) => {
        if (e.target !== '' && e.target !== undefined) {
            const {historical} = this.state;
            let newValue = e.target.value.replace(/[^0-9]/g, '');
            historical[i][field] = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.setState({historical});
        }
    }

    handleTTMChange = (field, e) => {
        if (e.target !== '' && e.target !== undefined) {
            const {ttm} = this.state;
            let newValue = e.target.value.replace(/[^0-9]/g, '');
            ttm[field] = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.setState({ttm});
        }
    }

    addNewFAQRow = () => {
        const {faq} = this.state;
        faq.push({
            q: '',
            a: '',
        });
        this.setState({
            faq
        });
    }

    removeFAQRow = i => {
        const {faq} = this.state;
        faq.splice(i, 1);
        this.setState({faq});
    }

    handleChangeFAQ = (field, i, e) => {
        if (e.target !== '' && e.target !== undefined) {
            const {faq} = this.state;
            faq[i][field] = e.target.value;
            this.setState({faq});
        }
    }

    updateSuggestedBuyers = checked => {
        this.setState({
            suggestedBuyers: checked
        });
        localStorage.setItem('suggestedBuyers', checked);
    }

    moveToCompleted = () => {
        api.post(`transaction/move_completed/${this.state.itemID}`, {
            company_sale_price: this.state.fields.price || '0',
            deal_on_market: this.state.deal_on_market,
            seller_finance_deal: this.state.seller_finance_deal,
            seller_buyer_type: this.state.selectedCompletedOption.label,
            seller_company_name: this.state.fields['seller_company_name'],
        }, true);
        this.setState({Deletelistmodal: !this.state.Deletelistmodal});
        window.location.reload();
    }

    onPrivateDeal = e => {
        e.preventDefault();
        this.setState({privateDealModal: !this.state.privateDealModal});
    }

    makePrivateDeal = async () => {
        if (!this.handlelistingValidation()) {
            this.setState({privateDealModal: false});
        } else {
            await this.SubmitListing(null, true);
            this.setState({privateDealModal: false});
            this.Listingtoggle();
            this.setState({privateDealModalOk: true});
            window.setTimeout(() => this.getListingList(), 1000);

        }
    }

    onChangeVisibility = (checked, deal) => {
        api.post(`transaction/visibility/${deal.id}`, {
            checked
        }, true).then(() => {
            this.getListingList();
            this.getPrivateListingsList()
        })
    }

    renewDeal = id => {
        api.post(`transaction/renew/${id}`, null, true).then(() => {
            this.getListingList();
            this.getPrivateListingsList()
            this.getArchivedDeal();
        });
    }

    triggerListingModal = () => {
        this.setState({triggerListingModal: true});
    }

    changeViewMode = viewMode => {
        localStorage.setItem('viewModeDeal', viewMode);
        this.setState({viewMode});
    }
    handlePageChange = async (pageNumber, cb) => {
        this.setState(prevState => ({
            ...prevState,
            currentPage: pageNumber
        }), () => this.getListingList(cb))
    }

    render() {
        const {establishedselectedOption} = this.state; //

        const locationVisOptions = [
            {value: 0, label: "Hidden"},
            {value: 10, label: "Show country only"},
            {value: 20, label: "Show state/province and country"},
            {value: 30, label: "Show county, state and country"},
            {value: 40, label: "Show city, county, state, and country"},
        ];

        const getLocationVisLabel = () => {
            return locationVisOptions.find(o => o.value === this.state.location_visibility).label;
        }

        const getPropDealColor = deal => {
            if (deal.completed === 0) {
                return 'green'
            }
            if (deal.completed === 3) {
                return 'gold'
            }
            if (deal.completed === 4) {
                return 'red'
            }
            if (deal.completed === 5) {
                return 'gray'
            }

        }


        return (
            <Row>
                <div className="loader" id="loaderlistingview" style={{display: 'none'}}>
                    <div className="spiner"/>
                </div>
                <Modal isOpen={this.state.Listingmodal} toggle={this.Listingtoggle}
                       className="profile-modal listing-modal" keyboard={false} backdrop="static" id="listingmodal">

                    <ModalHeader toggle={this.Listingtoggle} >
                        {this.state.success["success"] === "Saved successfully!" &&
                            <p className="text-center me-4 ms-4">
                                <h2 className="h2 mb-4">{this.context.user_type_id === 3 ? 'Congrats! Your listing has gone live on BizNexus. Share it now!!' :
                                    <>
                                        <h1>{this.state.created_transaction_id ? `You're Almost There!` : 'Your deal has been successfully updated'}</h1>
                                        {this.state.created_transaction_id &&
                                            <p>Your deal has been submitted and is now under review. We'll be in touch
                                                shortly to let you know if your listing needs some TLC or if we set it
                                                live on the platform</p>}
                                    </>}</h2>
                                {this.context.user_type_id === 3 && <a className="btn btn-primary mb-3"
                                                                       href={`${process.env.REACT_APP_ROOT}business-for-sale/${this.state.created_transaction_id}`}>View
                                    Your Listing Now</a>}
                                {this.context.user_type_id === 3 && <div>
                                    <LinkedinShareButton style={{padding: 10}}
                                                         url={`${process.env.REACT_APP_ROOT}business-for-sale/-/${this.state.created_transaction_id}`}>
                                        <LinkedinIcon size={48} round={true}/>
                                    </LinkedinShareButton>
                                    <FacebookShareButton style={{padding: 10}}
                                                         url={`${process.env.REACT_APP_ROOT}business-for-sale/-/${this.state.created_transaction_id}`}>
                                        <FacebookIcon size={48} round={true}/>
                                    </FacebookShareButton>
                                    <TwitterShareButton style={{padding: 10}}
                                                        url={`${process.env.REACT_APP_ROOT}business-for-sale/-/${this.state.created_transaction_id}`}>
                                        <TwitterIcon size={48} round={true}/>
                                    </TwitterShareButton>
                                </div>}
                            </p>}


                        {this.state.success["success"] === "Saved successfully!" && <div className="divider"></div>}
                        <p className="text-center">{this.state.editId !== '' ? "Update Listing" : (this.context.user_type_id === 3 && this.state.success["success"] !== "Saved successfully!") ? this.state.privateTeaser ? "Add a Private Teaser" : "Add New Deal" : this.state.success["success"] !== "Saved successfully!" ? <>Create
                            Your Private Teaser<a href="https://youtu.be/JY6XI66Gjqg" rel="noopener noreferrer"
                                                  target="_blank"><i style={{margin: '0px 5px 5px 5px'}}
                                                                     className="sprite i-question"></i></a></> : ''}</p>
                    </ModalHeader>
                    <ModalBody className="pt-3 pb-5 plr-0">
                        {this.state.success["success"] === "Saved successfully!" && <div>
                            {this.state.created_transaction_id && <div>
                                <div className="text-center" style={{margin: 20}}>
                                    <h2 className="h2">Reach more buyers</h2>
                                    Boosting turns your listing into a BizNexus ad and make it appear in more places,
                                    including our newsletter, social media and multiple strategic locations in our
                                    marketplace.
                                    On average, boosted listings receive 5x more views and messages directly to your
                                    listed contact info, or through our own internal BizNexus messaging system.
                                    <br/><br/>
                                    <button
                                        onClick={() => this.boostModal(this.state.created_transaction_id, this.state.created_transaction_name)}
                                        className="btn btn-primary">Boost Your Deal Now
                                    </button>
                                </div>
                            </div>}
                        </div>}


                        {(this.state.success["success"] === "Saved successfully!") ? <div className="text-center">
                                <button onClick={() => {
                                    this.Listingtoggle();
                                    setTimeout(() => {
                                        this.Listingtoggle();
                                    }, 500)
                                }} className="btn btn-success">Add Another Listing Now
                                </button>
                            </div> :
                            <div className="position-relative">

                                <p className="text-center me-4 ms-4">{this.context.user_type_id === 3 ? this.state.privateTeaser ? 'Your teaser will be set as a private, unlisted opportunity.' : 'New listings added must only be established businesses for sale, franchise resales' :
                                    <>
                                        <p className="text-center">1. Add your deal info & submit your anonymous
                                            business profile</p>

                                        <p className="text-center">2. Our team will review and reach out to schedule
                                            your consultation</p>

                                        <p className="text-center">3. Once we’re clear on your goals, we’ll put together
                                            your best acquisition options and make the match only when YOU are ready.
                                            You retain 100% clarity and control.</p>
                                    </>
                                } </p>
                                <p className="text-center me-4 ms-4 mb-4">{this.context.user_type_id === 3 ? this.state.privateTeaser ? 'Visit your “buyer leads” page to view suggested acquirers that match with your deal, and contact whoever you choose.' : '(no new franchise opportunities allowed), business real estate for sale or lease, or asset sales.' : 'Acquisition opportunities posted on BizNexus must be established businesses or franchise resales, business real estate for sale or lease, or asset sales.'}</p>

                                <div className="loader bg-white" id="loaderlisting" style={{display: 'none'}}>
                                    <div className="spiner"></div>
                                </div>


                                <Form onSubmit={this.SubmitListing}>
                                    <FormGroup row>
                                        <Label htmlFor="listingtitle"
                                               md={4}>{this.context.user_type_id === 3 ? this.state.privateTeaser ? 'Enter Teaser Title' : 'Enter Title * ' : 'Opportunity Title '}
                                            {this.context.user_type_id !== 3 &&
                                                <span class="pricing-card__features-tooltip">
                                                    <i style={{margin: '0px 5px 5px 5px'}}
                                                       className="sprite i-question" id="titleTooltip"/>
                                            <UncontrolledTooltip placement="bottom" target={`titleTooltip`}
                                                                 autohide={false}
                                                                 innerClassName="areas-tooltip">
                                                Make it descriptive but keep it anonymous
                                            </UncontrolledTooltip>
                                            </span>}
                                            {this.state.fields["listingtitle"] ? `${this.state.fields["listingtitle"].length}/150` : ''}
                                        </Label>
                                        <Col md={7}>
                                            <Input type="text" name="listingtitle" id="listingtitle" ref="listingtitle"
                                                   onChange={this.handleChangelisting.bind(this, "listingtitle")}
                                                   value={this.state.fields["listingtitle"]} placeholder="Enter title"/>
                                            <span className="error  ">{this.state.errors["listingtitle"]}</span>
                                        </Col>
                                    </FormGroup>
                                    {this.context.user_type_id === 3 && <FormGroup row>
                                        <Label htmlFor="referencenumber" md={4}>Reference Number</Label>
                                        <Col md={7}>
                                            <Input type="text" name="referencenumber" id="referencenumber"
                                                   ref="referencenumber"
                                                   onChange={this.handleChangelisting.bind(this, "referencenumber")}
                                                   value={this.state.fields["referencenumber"]}
                                                   placeholder="Optional - Add listing/reference number here if applicable."/>
                                        </Col>
                                    </FormGroup>}

                                    {this.context.user_type_id !== 3 && <FormGroup row>
                                        <Label htmlFor="description" md={4}>When do you want to sell?</Label>
                                        <Col md={7}>
                                            <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                                name="want_to_sell"
                                                id="want_to_sell"
                                                isClearable={false}
                                                onChange={e => this.setState({want_to_sell: e.value})}
                                                options={[
                                                    {value: 'ASAP', label: 'ASAP'},
                                                    {value: 'Open to offers', label: 'Open to offers'},
                                                    {
                                                        value: 'Within the next 2 years',
                                                        label: 'Within the next 2 years'
                                                    },
                                                    {value: '2-5 years', label: '2-5 years'},
                                                    {
                                                        value: 'Not for at least 5 years',
                                                        label: 'Not for at least 5 years'
                                                    },
                                                ]}
                                                value={{value: this.state.want_to_sell, label: this.state.want_to_sell }}
                                            />
                                        </Col>
                                    </FormGroup>}

                                    <FormGroup row>
                                        <Label htmlFor=""
                                               md={4}>{this.context.user_type_id === 3 ? this.state.privateTeaser ? 'Photo' : 'Listing Photo' : 'Deal Photo'}
                                            <span className="pricing-card__features-tooltip">
                                                    <i style={{margin: '0px 5px 5px 5px'}}
                                                       className="sprite i-question" id="photoTooltip"/>
                                            <UncontrolledTooltip placement="bottom" target={`photoTooltip`}
                                                                 autohide={false}
                                                                 innerClassName="areas-tooltip">
                                                Upload your image here (Recommended: 3334x2500 pixels with a 4:3 aspect ratio). Supported formats: JPEG, PNG, JPG. Use the cropping tool if needed.
                                            </UncontrolledTooltip>
                                            </span>
                                        </Label>
                                        <Col md={7}>
                                            {(this.state.listingimage && this.state.editId !== '') &&
                                                <div className="img-preview">
                                                    <img
                                                        src={(this.state.listingimage) ? (this.state.listingimage.indexOf('base64') > -1) ? `${this.state.listingimage}` : (this.state.listingimage.indexOf('listing_') > -1) ? getImageUrl(this.state.listingimage, true) : getImageUrl(this.state.listingimage, true) : process.env.PUBLIC_URL + "/images/profile_pic.png"}
                                                        className="img-fluid" alt={this.state.fields["listingtitle"]}/>
                                                </div>}

                                            {this.state.listingimg
                                                ? <div className="user-banner profile-pic-banner m-0 mt-3">
                                                    <ImageEditor
                                                        zoom
                                                        image={this.state.listingimg}
                                                        setRef={ref => this.cropperlistingimg = ref}
                                                        width={320}
                                                        height={180}
                                                    />
                                                </div>
                                                : <div className="banner-drop profile-drop user-banner m-0 mt-3">
                                                    <Dropzone
                                                        text="Click to upload or drag JPG or PNG here"
                                                        maxFileSize={5024000}
                                                        multiple={false}
                                                        onSuccess={this.onChangelistingimg}
                                                        onFailure={this.onListingImageFail}
                                                    />
                                                </div>
                                            }
                                            <span className="error  ">{this.state.customMessage}</span>
                                            {this.state.listingimg && <p style={{
                                                margin: '0 0 10px',
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}>
                                                <Button color="danger"
                                                        size="sm"
                                                        onClick={this.clearDragNCrop}
                                                >Clear</Button>
                                            </p>}
                                            <p style={{fontStyle: 'italic', fontSize: 14}}>Need help finding a good
                                                photo? <a rel="noopener noreferrer" href="https://pixabay.com/"
                                                          target="_blank">Click HERE.</a></p>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="custom-menu-category" row>
                                        <Label htmlFor="category" md={4}>Choose industries* <i
                                            style={{margin: '0px 5px 5px 5px'}} id="categoryTooltip"
                                            className="sprite i-question"/>
                                            <UncontrolledTooltip placement="bottom" target="categoryTooltip"
                                                                 autohide={false} innerClassName="areas-tooltip">Maximum
                                                2 selection allowed</UncontrolledTooltip></Label>
                                        <Col md={7}>
                                            <Dropdown
                                                className={this.state.secondlevelmax !== "" ? 'invalid-dropdown' : ''}
                                                isOpen={this.state.ListingdropdownCategory}
                                                toggle={this.ListingCategorytoggle}>
                                                <DropdownToggle tag="a" className="form-control" caret>
                                                    Select Industry
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <IndustryFilter
                                                        industriesList={this.state.industrylistListing}
                                                        selectedIndustries={this.state.brokerIndustry}
                                                        updateIndustries={this.onUpdateBrokerIndustry}
                                                        onRemoveIndustry={this.onRemoveBrokerIndustry}
                                                    />
                                                </DropdownMenu>
                                            </Dropdown>
                                            <div className="d-flex flex-wrap align-items-center row-gutter-10 ms-1">
                                                {this.state.brokerIndustry.map((item, i) =>
                                                    <>
                                                        <div className="mt-1 ms-1">
                                                            <button onClick={(e) => e.preventDefault()}
                                                                    className="btn btn-sm btn-primary-blue btn-text-xsm"
                                                                    style={{
                                                                        padding: '1.5px 11px',
                                                                        wordBreak: 'break-all'
                                                                    }}>
                                                                <span className="me-2">{item.label}</span>
                                                                <svg id={i + "_parentselected"}
                                                                     onClick={() => this.onRemoveBrokerIndustry(item)}
                                                                     className="icon"
                                                                     viewBox="0 0 14 13" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                          d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z"
                                                                          fill="currentColor"/>
                                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                                          d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z"
                                                                          fill="currentColor"/>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            {this.state.secondlevelmax === "" &&
                                                <span className="error  mt-1">{this.state.errors["category"]}</span>}
                                            {this.state.secondlevelmax !== "" &&
                                                <span className="error  mt-1">Maximum 2 selection allowed</span>}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label htmlFor="description" md={4}>Description*</Label>
                                        <Col md={7}>
                                            <Input type="textarea" style={{height: '270px'}} name="description"
                                                   id="description" ref="description"
                                                   onChange={this.handleChangelisting.bind(this, "description")}
                                                   value={this.state.fields["description"]}
                                                   placeholder="Enter description of the opportunity. Do not include email addresses, websites, and phone numbers."/>
                                            <span className="error  ">{this.state.errors['description']}</span>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label htmlFor="location" md={4}>Location* (Optional for Internet Co's)</Label>
                                        <Col md={7}>
                                            <div className="position-relative">
                                                <Geosuggest
                                                    inputClassName="search-input form-control"
                                                    id="location"
                                                    name="location"
                                                    ref="location"
                                                    placeholder="Enter your location"
                                                    types={['(cities)']}
                                                    onSuggestSelect={this.handleGeosuggestChange}
                                                    initialValue={this.state.location}
                                                />
                                            </div>
                                            <span className="error  ">{this.state.errors["location"]}</span>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label htmlFor="description" md={4}>Public Location Visibility</Label>
                                        <Col md={7}>
                                            <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                                name="location_visibility"
                                                id="location_visibility"
                                                isClearable={false}
                                                onChange={e => this.setState({location_visibility: e.value})}
                                                options={locationVisOptions}
                                                value={{
                                                    value: this.state.location_visibility,
                                                    label: getLocationVisLabel()
                                                }}

                                            />
                                        </Col>
                                    </FormGroup>
                                    {this.context.user_type_id === 3 && <FormGroup row>
                                        <Label htmlFor="nda" md={4}>Link to NDA file</Label>
                                        <Col md={7}>
                                            <Input placeholder="https://..." type="text" name="nda" id="nda" ref="nda"
                                                   onChange={this.handleChangelisting.bind(this, "nda")}
                                                   value={this.state.fields["nda"]}/>
                                        </Col>
                                    </FormGroup>}
                                    {this.context.user_type_id === 3 && <FormGroup row>
                                        <Label htmlFor="teaser" md={4}>Upload a teaser / Link
                                            <i style={{margin: '0px 5px 5px 5px'}} id="teaserTooltip"
                                               className="sprite i-question"></i>
                                            <UncontrolledTooltip placement="bottom" target="teaserTooltip"
                                                                 autohide={false} innerClassName="areas-tooltip">We
                                                allow paid subscription buyers to access and download teasers for your
                                                listing.</UncontrolledTooltip>
                                        </Label>
                                        <Col md={7}>
                                            <Input type="text" name="teaser" id="teaser" ref="teaser"
                                                   onChange={this.handleChangelisting.bind(this, "teaser")}
                                                   value={this.state.fields["teaser"]}/>
                                        </Col>
                                    </FormGroup>}
                                    <FormGroup row>
                                        <Label htmlFor="video" md={4}>Video link
                                            <i id="videoTooltip" style={{margin: '0px 5px 5px 5px'}}
                                               className="sprite i-question"></i>
                                            <UncontrolledTooltip placement="bottom" target="videoTooltip"
                                                                 autohide={false} innerClassName="areas-tooltip">Feel
                                                free to add a youtube or vimeo URL to show video relevant to your
                                                listing. NOTE any listings with video not related to the specific
                                                listing will be taken down from our site.</UncontrolledTooltip>
                                        </Label>
                                        <Col md={7}>
                                            <Input type="text" name="video" id="video" ref="video"
                                                   onChange={this.handleChangelisting.bind(this, "video")}
                                                   value={this.state.fields["video"]}/>

                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label htmlFor="Keywords" md={4}>Keywords</Label>
                                        <Col md={7}>
                                            <Input type="textarea" style={{height: '100px'}} name="keywords"
                                                   id="Keywords" ref="Keywords"
                                                   onChange={this.handleChangelisting.bind(this, "keywords_search")}
                                                   value={this.state.fields["keywords_search"]}
                                                   placeholder="Enter keywords separated by a comma or space to help find your deal."/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label htmlFor="TargetMarkets" md={4}>Target Markets</Label>
                                        <Col md={7}>
                                            <Input type="textarea" style={{height: '100px'}} name="TargetMarkets"
                                                   id="TargetMarkets" ref="TargetMarkets"
                                                   onChange={this.handleChangelisting.bind(this, "target_markets")}
                                                   value={this.state.fields["target_markets"]}
                                                   placeholder="Tell us about the target markets this company serves and sells to. B2B? B2C? Enterprise accounts or public contracts?"/>
                                        </Col>
                                    </FormGroup>
                                    <Button className="btn-toggle" data-class={this.state.financialclass}
                                            onClick={this.financialtoggle}><i
                                        className="arrow-right"></i>&nbsp;&nbsp;&nbsp;Add Financial Details</Button>
                                    <Collapse isOpen={this.state.financialcollapse} className="mt-3">
                                        <FormGroup row>
                                            <Label htmlFor="price" md={4}>Asking Price</Label>
                                            <Col md={7}>
                                                <InputGroup>
                                                    <InputGroupText>
                                                        $
                                                    </InputGroupText>
                                                    <Input type="text" name="price" id="price" ref="price"
                                                           onChange={this.handleChangelisting.bind(this, "price")}
                                                           value={this.state.fields["price"]}/>
                                                </InputGroup>
                                                <span className="error  ">{this.state.errors["price"]}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="" md={4}>Do Not Show Asking Price</Label>
                                            <Col md={7}>
                                                <InputGroup>
                                                    <Input style={{marginLeft: '5px', marginTop: '17px'}}
                                                           type="checkbox" name="show_price"
                                                           onChange={this.handleChangelisting.bind(this, "show_price")}
                                                           value={this.state.fields["show_price"]}
                                                           checked={this.state.fields["show_price"]}/>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="revenue"
                                                   md={4}>{this.context.user_type_id === 3 ? 'Revenue' : <>
                                                Estimated Annual Revenue
                                                <span class="pricing-card__features-tooltip">
                                                    <i style={{margin: '0px 5px 5px 5px'}}
                                                       className="sprite i-question" id="annualRevenueTooltip"/>
                                            <UncontrolledTooltip placement="bottom" target={`annualRevenueTooltip`}
                                                                 autohide={false}
                                                                 innerClassName="areas-tooltip">
                                                Enter your approximate annual revenues so you can attract & qualify the appropriate buyers for your deal
                                            </UncontrolledTooltip>
                                                </span>
                                            </>} </Label>
                                            <Col md={7}>
                                                <InputGroup>
                                                    <InputGroupText>
                                                        $
                                                    </InputGroupText>
                                                    <Input type="text" name="revenue" id="revenue" ref="revenue"
                                                           onChange={this.handleChangelisting.bind(this, "revenue")}
                                                           value={this.state.fields["revenue"]}/>
                                                </InputGroup>
                                                <span className="error  ">{this.state.errors["revenue"]}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="cashflow"
                                                   md={4}>{this.context.user_type_id === 3 ? 'SDE' : <>
                                                Estimated SDE
                                                <span class="pricing-card__features-tooltip">
                                                    <i style={{margin: '0px 5px 5px 5px'}}
                                                       className="sprite i-question" id="sdeCash"/>
                                            <UncontrolledTooltip placement="bottom" target={`sdeCash`}
                                                                 autohide={false}
                                                                 innerClassName="areas-tooltip">
                                                Enter your approximate SDE cash flow so you can attract & qualify the appropriate buyers for your deal. (SDE is a cash flow measure typically used for smaller deals under $600k)
                                            </UncontrolledTooltip>
                                                </span>
                                            </>} </Label>
                                            <Col md={7}>
                                                <InputGroup>
                                                    <InputGroupText>
                                                        $
                                                    </InputGroupText>
                                                    <Input type="text" name="cashflow" id="cashflow" ref="cashflow"
                                                           onChange={this.handleChangelisting.bind(this, "cashflow")}
                                                           value={this.state.fields["cashflow"]}/>
                                                </InputGroup>
                                                <span className="error  ">{this.state.errors["cashflow"]}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="ebitda"
                                                   md={4}>{this.context.user_type_id === 3 ? 'EBITDA' : <>
                                                Estimated EBITDA
                                                <span class="pricing-card__features-tooltip">
                                                    <i style={{margin: '0px 5px 5px 5px'}}
                                                       className="sprite i-question" id="ebitdaCash"/>
                                            <UncontrolledTooltip placement="bottom" target={`ebitdaCash`}
                                                                 autohide={false}
                                                                 innerClassName="areas-tooltip">
                                                Enter your approximate EBITDA cash flow so you can attract & qualify the appropriate buyers for your deal. (EBITDA is a cash flow measure typically used for larger deals over $600k)
                                            </UncontrolledTooltip>
                                                </span>
                                            </>} </Label>
                                            <Col md={7}>
                                                <InputGroup>
                                                    <InputGroupText>
                                                        $
                                                    </InputGroupText>
                                                    <Input type="text" name="ebitda" id="ebitda" ref="ebitda"
                                                           onChange={this.handleChangelisting.bind(this, "ebitda")}
                                                           value={this.state.fields["ebitda"]}/>
                                                </InputGroup>
                                                <span className="error  ">{this.state.errors["ebitda"]}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="inventory" md={4}>Inventory</Label>
                                            <Col md={7}>
                                                <InputGroup>
                                                    <InputGroupText>
                                                        $
                                                    </InputGroupText>
                                                    <Input type="text" name="inventory" id="inventory" ref="inventory"
                                                           onChange={this.handleChangelisting.bind(this, "inventory")}
                                                           value={this.state.fields["inventory"]}/>
                                                </InputGroup>
                                                <span className="error  ">{this.state.errors["inventory"]}</span>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label htmlFor="financing" md={4}>Inventory included in asking
                                                price?</Label>
                                            <Col md={7}>
                                                <InputGroup>
                                                    <form>
                                                        <div><Label htmlFor="InventoryYes">Yes</Label>
                                                            <Input type="radio"
                                                                   id="InventoryYes"
                                                                   value={1}
                                                                   style={{marginLeft: '20px'}}
                                                                   checked={this.state.fields['included_in_asking_price'] === true}
                                                                   onChange={e => {
                                                                       const {fields} = this.state;
                                                                       fields.included_in_asking_price = true;
                                                                       this.setState({fields});
                                                                   }}/></div>
                                                        <div><Label htmlFor="InventoryNo">No</Label>
                                                            <Input type="radio"
                                                                   id="InventoryNo"
                                                                   value={2}
                                                                   style={{marginLeft: '24px'}}
                                                                   checked={!this.state.fields['included_in_asking_price']}
                                                                   onChange={e => {
                                                                       const {fields} = this.state;
                                                                       fields.included_in_asking_price = false;
                                                                       this.setState({fields});
                                                                   }}/></div>
                                                    </form>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="ppe" md={4}>FF&E
                                                {this.state.userId !== 3 &&
                                                    <span class="pricing-card__features-tooltip">
                                                    <i style={{margin: '0px 5px 5px 5px'}}
                                                       className="sprite i-question" id="ffeTootlip"/>
                                            <UncontrolledTooltip placement="bottom" target={`ffeTootlip`}
                                                                 autohide={false}
                                                                 innerClassName="areas-tooltip">
                                                FFE is movable furniture, fixtures or equipment. What's the value of your business's standup desks, laptops, office pool table, etc?
                                            </UncontrolledTooltip>
                                                </span>}
                                            </Label>
                                            <Col md={7}>
                                                <InputGroup>
                                                    <InputGroupText>
                                                        $
                                                    </InputGroupText>
                                                    <Input type="text" name="ppe" id="ppe" ref="ppe"
                                                           onChange={this.handleChangelisting.bind(this, "ppe")}
                                                           value={this.state.fields["ppe"]}/>
                                                </InputGroup>
                                                <span className="error  ">{this.state.errors["ppe"]}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="real_estate" md={4}>Real Estate
                                                {this.state.userId !== 3 &&
                                                    <span class="pricing-card__features-tooltip">
                                                    <i style={{margin: '0px 5px 5px 5px'}}
                                                       className="sprite i-question" id="realEstateTooltip" />
                                            <UncontrolledTooltip placement="bottom" target={`realEstateTooltip`}
                                                                 autohide={false}
                                                                 innerClassName="areas-tooltip">
                                                What's the value of the real estate if you have an office? Do you lease the space or do you own it?
                                            </UncontrolledTooltip>
                                                </span>}
                                            </Label>
                                            <Col md={7}>
                                                <InputGroup>
                                                    <InputGroupText>
                                                        $
                                                    </InputGroupText>
                                                    <Input type="text" name="real_estate" id="real_estate"
                                                           ref="real_estate"
                                                           onChange={this.handleChangelisting.bind(this, "real_estate")}
                                                           value={this.state.fields["real_estate"]}/>
                                                </InputGroup>
                                                <span className="error  ">{this.state.errors["real_estate"]}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="Leased" md={4}>Leased / Owned</Label>
                                            <Col md={7}>
                                                <InputGroup>
                                                    <form>
                                                        <div><Label htmlFor="Leased">Leased</Label>
                                                            <Input type="radio"
                                                                   id="Leased"
                                                                   value={1}
                                                                   style={{marginLeft: '20px'}}
                                                                   checked={parseInt(this.state.fields['leased_or_owned']) === 1}
                                                                   onChange={e => {
                                                                       const {fields} = this.state;
                                                                       fields.leased_or_owned = 1;
                                                                       this.setState({fields});
                                                                   }}/></div>
                                                        <div><Label htmlFor="Owned">Owned</Label>
                                                            <Input type="radio"
                                                                   id="Owned"
                                                                   value={2}
                                                                   style={{marginLeft: '20px'}}
                                                                   checked={parseInt(this.state.fields['leased_or_owned']) === 2}
                                                                   onChange={e => {
                                                                       const {fields} = this.state;
                                                                       fields.leased_or_owned = 2;
                                                                       this.setState({fields});
                                                                   }}/></div>
                                                    </form>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="" md={4}>Total annual debt payments (confidential)<i
                                                style={{margin: '0px 5px 5px 5px'}} id="debt"
                                                className="sprite i-question"></i>
                                                <UncontrolledTooltip placement="bottom" target="debt" autohide={false}
                                                                     innerClassName="areas-tooltip">If the company has
                                                    debt, and we can help you fix that to get a higher selling price,
                                                    we'll let you know. We will NOT show this metric on the public
                                                    listing page. This is a free value-add service
                                                    for {this.context.user_type_id === 3 ? 'business brokers' : 'entrepreneur'} to
                                                    make businesses with problematic debt issues more
                                                    sellable.</UncontrolledTooltip></Label>
                                            <Col md={7}>
                                                <InputGroup>
                                                    <InputGroupText>
                                                        $
                                                    </InputGroupText>
                                                    <Input name="debt_payments" type="text"
                                                           onChange={this.handleChangelisting.bind(this, "debt_payments")}
                                                           value={this.state.fields["debt_payments"]}/>
                                                </InputGroup>
                                                <span className="error  ">{this.state.errors["debt_payments"]}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="" md={4}>Is this an asset sale?
                                                {this.state.userId !== 3 &&
                                                    <span class="pricing-card__features-tooltip">
                                                    <i style={{margin: '0px 5px 5px 5px'}}
                                                       className="sprite i-question" id="assetSaleTooltip"/>
                                            <UncontrolledTooltip placement="bottom" target={`assetSaleTooltip`}
                                                                 autohide={false}
                                                                 innerClassName="areas-tooltip">
                                                This is when a business sells actual assets (tangible or intangible), but not legal ownership of the company itself. The buyer assumes no liabilities and the seller has no claim on the sold assets.
                                            </UncontrolledTooltip>
                                                </span>}
                                            </Label>
                                            <Col md={7}>
                                                <InputGroup>
                                                    <Input style={{marginLeft: '5px', marginTop: '17px'}}
                                                           type="checkbox" name="asset_sale"
                                                           onChange={this.handleChangelisting.bind(this, "asset_sale")}
                                                           value={this.state.fields["asset_sale"]}
                                                           checked={this.state.fields["asset_sale"]}/>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label md={4}
                                                   htmlFor="history">{this.context.user_type_id === 3 ? 'Historical view' : <>
                                                Est. historical performance
                                                <span class="pricing-card__features-tooltip">
                                                    <i style={{margin: '0px 5px 5px 5px'}}
                                                       className="sprite i-question" id="historyTooltip" />
                                            <UncontrolledTooltip placement="bottom" target={`historyTooltip`}
                                                                 autohide={false}
                                                                 innerClassName="areas-tooltip">
                                                Complete this to show the graph of your historical growth in these key areas
                                            </UncontrolledTooltip>
                                                </span>
                                            </>} </Label>
                                            <Col md={7}>
                                                <Table responsive className="tbl-transaction">
                                                    <thead>
                                                    {/*<tr>*/}
                                                    {/*    <th className="text-center">Year</th>*/}
                                                    {/*    <th className="text-center">Revenue</th>*/}
                                                    {/*    <th className="text-center">SDE</th>*/}
                                                    {/*    <th className="text-center">EBITDA</th>*/}
                                                    {/*    <th className="text-center">#</th>*/}
                                                    {/*</tr>*/}
                                                    <tr>
                                                        <th className="text-center">#</th>
                                                        <th className="text-center">TTM</th>
                                                        <th className="text-center">{years[0]}</th>
                                                        <th className="text-center">{years[1]}</th>
                                                        <th className="text-center">{years[2]}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td className="text-center">Revenue</td>
                                                        <td><input value={this.state.ttm.revenue}
                                                                   onChange={this.handleTTMChange.bind(this, "revenue")}
                                                                   style={{height: '23px', padding: '5px'}} type="text"
                                                                   className="form-control"/></td>
                                                        <td><input value={this.state.historical[0].revenue}
                                                                   onChange={this.handleRowChange.bind(this, "revenue", 0)}
                                                                   style={{height: '23px', padding: '5px'}} type="text"
                                                                   className="form-control"/></td>
                                                        <td><input value={this.state.historical[1].revenue}
                                                                   onChange={this.handleRowChange.bind(this, "revenue", 1)}
                                                                   style={{height: '23px', padding: '5px'}} type="text"
                                                                   className="form-control"/></td>
                                                        <td><input value={this.state.historical[2].revenue}
                                                                   onChange={this.handleRowChange.bind(this, "revenue", 2)}
                                                                   style={{height: '23px', padding: '5px'}} type="text"
                                                                   className="form-control"/></td>
                                                    </tr>

                                                    <tr>
                                                        <td className="text-center">SDE</td>
                                                        <td><input value={this.state.ttm.cashflow}
                                                                   onChange={this.handleTTMChange.bind(this, "cashflow")}
                                                                   style={{height: '23px', padding: '5px'}} type="text"
                                                                   className="form-control"/></td>
                                                        <td><input value={this.state.historical[0].cashflow}
                                                                   onChange={this.handleRowChange.bind(this, "cashflow", 0)}
                                                                   style={{height: '23px', padding: '5px'}} type="text"
                                                                   className="form-control"/></td>
                                                        <td><input value={this.state.historical[1].cashflow}
                                                                   onChange={this.handleRowChange.bind(this, "cashflow", 1)}
                                                                   style={{height: '23px', padding: '5px'}} type="text"
                                                                   className="form-control"/></td>
                                                        <td><input value={this.state.historical[2].cashflow}
                                                                   onChange={this.handleRowChange.bind(this, "cashflow", 2)}
                                                                   style={{height: '23px', padding: '5px'}} type="text"
                                                                   className="form-control"/></td>
                                                    </tr>

                                                    <tr>
                                                        <td className="text-center">EBITDA</td>
                                                        <td><input value={this.state.ttm.ebitda}
                                                                   onChange={this.handleTTMChange.bind(this, "ebitda")}
                                                                   style={{height: '23px', padding: '5px'}} type="text"
                                                                   className="form-control"/></td>
                                                        <td><input value={this.state.historical[0].ebitda}
                                                                   onChange={this.handleRowChange.bind(this, "ebitda", 0)}
                                                                   style={{height: '23px', padding: '5px'}} type="text"
                                                                   className="form-control"/></td>
                                                        <td><input value={this.state.historical[1].ebitda}
                                                                   onChange={this.handleRowChange.bind(this, "ebitda", 1)}
                                                                   style={{height: '23px', padding: '5px'}} type="text"
                                                                   className="form-control"/></td>
                                                        <td><input value={this.state.historical[2].ebitda}
                                                                   onChange={this.handleRowChange.bind(this, "ebitda", 2)}
                                                                   style={{height: '23px', padding: '5px'}} type="text"
                                                                   className="form-control"/></td>
                                                    </tr>


                                                    </tbody>
                                                </Table>
                                                {/*<Button onClick={() => this.addNewHistoricalRow()}>Add Year</Button>*/}
                                            </Col>
                                        </FormGroup>
                                    </Collapse>
                                    <Button className="btn-toggle" data-class={this.state.businessclass}
                                            onClick={this.businesstoggle}><i
                                        className="arrow-right"></i>&nbsp;&nbsp;&nbsp;Add Business Information</Button>
                                    <Collapse isOpen={this.state.businesscollapse} className="mt-3">
                                        <FormGroup row>
                                            <Label htmlFor="established" md={4}>Established</Label>
                                            <Col md={7}>
                                                <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                                    name="established"
                                                    id="established"
                                                    isSearchable={true}
                                                    isClearable={false}
                                                    onChange={this.handleChange}
                                                    options={this.state.establishedyear}
                                                    value={establishedselectedOption}
                                                    ref="established"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="reasonselling" md={4}>Reason for Selling</Label>
                                            <Col md={7}>
                                                <Input type="textarea" style={{height: '100px'}} name="reasonselling"
                                                       id="reasonselling" ref="reasonselling"
                                                       onChange={this.handleChangelisting.bind(this, "reasonselling")}
                                                       value={this.state.fields["reasonselling"]}
                                                       placeholder="Enter Reason for Selling"/>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label htmlFor="competitive_advantages" md={4}>Competitive
                                                Advantages</Label>
                                            <Col md={7}>
                                                <Input type="textarea" style={{height: '100px'}}
                                                       name="competitive_advantages" id="competitive_advantages"
                                                       ref="competitive_advantages"
                                                       onChange={this.handleChangelisting.bind(this, "competitive_advantages")}
                                                       value={this.state.fields["competitive_advantages"]}
                                                       placeholder=""/>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label htmlFor="facilities" md={4}>Facilities/Assets Overview</Label>
                                            <Col md={7}>
                                                <Input type="textarea" style={{height: '100px'}} name="facilities"
                                                       id="facilities" ref="facilities"
                                                       onChange={this.handleChangelisting.bind(this, "facilities")}
                                                       value={this.state.fields["facilities"]} placeholder="&bull; Is the real estate owned or leased?
&bull; Is the real estate included in the asking price? 
&bull; What is the building square footage?
&bull; Is the inventory included in the asking price?"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="financing" md={4}>Is seller financing available?</Label>
                                            <Col md={7}>
                                                <InputGroup>
                                                    <form>
                                                        <div><Label htmlFor="Leased">Yes</Label>
                                                            <Input type="radio"
                                                                   id="Leased"
                                                                   value={1}
                                                                   style={{marginLeft: '20px'}}
                                                                   checked={this.state.fields['seller_financing'] === true}
                                                                   onChange={e => {
                                                                       const {fields} = this.state;
                                                                       fields.seller_financing = true;
                                                                       this.setState({fields});
                                                                   }}/></div>
                                                        <div><Label htmlFor="Owned">No</Label>
                                                            <Input type="radio"
                                                                   id="Owned"
                                                                   value={2}
                                                                   style={{marginLeft: '24px'}}
                                                                   checked={!this.state.fields['seller_financing']}
                                                                   onChange={e => {
                                                                       const {fields} = this.state;
                                                                       fields.seller_financing = false;
                                                                       this.setState({fields});
                                                                   }}/></div>
                                                    </form>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="financing" md={4}>Financing Overview
                                                {this.context.user_type_id !== 3 &&
                                                    <span class="pricing-card__features-tooltip" id="Financing">
                                                    <UncontrolledTooltip placement="top" target="Financing"
                                                                         autohide={false}
                                                                         innerClassName="areas-tooltip">If you don’t know what financing might be available, schedule a meeting with one of lending partners to nail down options available to you and a potential buyer. Link that opens in new tab: <a
                                                        href="https://www.biznexus.com/acquisition-financing">https://www.biznexus.com/acquisition-financing</a></UncontrolledTooltip>
                                                    <i style={{margin: '0px 5px 5px 5px'}}
                                                       className="sprite i-question"></i>
                                                </span>}
                                            </Label>
                                            <Col md={7}>
                                                <Input type="textarea" style={{height: '100px'}} name="financing"
                                                       id="financing" ref="financing"
                                                       onChange={this.handleChangelisting.bind(this, "financing")}
                                                       value={this.state.fields["financing"]}
                                                       placeholder="Enter any available financing for the buyer."/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="competition" md={4}>Competition Overview</Label>
                                            <Col md={7}>
                                                <Input type="textarea" style={{height: '100px'}} name="competition"
                                                       id="competition" ref="competition"
                                                       onChange={this.handleChangelisting.bind(this, "competition")}
                                                       value={this.state.fields["competition"]}
                                                       placeholder="Tell us about competition and how your deal differentiates"/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="growthopportunity" md={4}>Growth Opportunity</Label>
                                            <Col md={7}>
                                                <Input type="textarea" style={{height: '100px'}}
                                                       name="growthopportunity" id="growthopportunity"
                                                       ref="growthopportunity"
                                                       onChange={this.handleChangelisting.bind(this, "growthopportunity")}
                                                       value={this.state.fields["growthopportunity"]}
                                                       placeholder={`Tell us about growth opportunity a potential acquirer will be buying into..What are the "low hanging fruit" growth opportunities for a new owner?`}/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label htmlFor="ongoingsupport" md={4}>Ongoing Support</Label>
                                            <Col md={7}>
                                                <Input type="textarea" style={{height: '100px'}} name="ongoingsupport"
                                                       id="ongoingsupport" ref="ongoingsupport"
                                                       onChange={this.handleChangelisting.bind(this, "ongoingsupport")}
                                                       value={this.state.fields["ongoingsupport"]}
                                                       placeholder="Mgmt. team willing to stay on board? Will current owner stay on in any operational or consulting capacity? "/>
                                            </Col>
                                        </FormGroup>
                                    </Collapse>
                                    <Button className="btn-toggle" data-class={this.state.faqclass}
                                            onClick={this.faqtoggle.bind(this)}><i
                                        className="arrow-right"></i>&nbsp;&nbsp;&nbsp;FAQ</Button>
                                    <Collapse isOpen={this.state.faqcollapse} className="mt-3">
                                        <p className="text-center">Try to anticipate Q&A that will attract a potential
                                            buyer to your business</p>
                                        {this.state.faq.map((f, i) => <div>
                                            <FormGroup row>
                                                <Label md={4}>Question {i + 1}</Label>
                                                <Col md={7}>
                                                    <Input type="text"
                                                           onChange={this.handleChangeFAQ.bind(this, "q", i)}
                                                           value={f.q}/>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label md={4}>Answer {i + 1}</Label>
                                                <Col md={7}>
                                                    <Input type="textarea" style={{height: '100px'}}
                                                           onChange={this.handleChangeFAQ.bind(this, "a", i)}
                                                           value={f.a}/>
                                                </Col>
                                            </FormGroup>
                                            {i > 0 && <FormGroup row>
                                                <Label md={4}></Label>
                                                <Col md={7}>
                                                    <Button color="primary" className="btn" size="sm"
                                                            onClick={() => this.removeFAQRow(i)}>Remove
                                                        question {i + 1}</Button>
                                                </Col>
                                            </FormGroup>}
                                        </div>)}
                                        <FormGroup row>
                                            <Label md={4}></Label>
                                            <Col md={7}><Button onClick={() => this.addNewFAQRow()}>+ Add
                                                Question</Button></Col>
                                        </FormGroup>
                                    </Collapse>
                                    <div className="text-center">
                                        <button style={{fontSize: 16, padding: '13px 25px'}} type="submit"
                                                className="btn btn-primary w-300">{this.state.editId !== '' ? "Save" : this.context.user_type_id === USER_TYPE.BROKER ? this.state.privateTeaser ? "Add Private Teaser" : "Add Listing" : 'Submit For Review'}</button>
                                    </div>
                                </Form>
                            </div>}
                    </ModalBody>
                </Modal>
                <Modal className="modal-dialog modal-featured" size={'lg'} isOpen={this.state.Deletelistmodal}
                       toggle={this.Deletelisttoggle} keyboard={false} backdrop="static">
                    <ModalHeader style={{padding: '1rem 2rem'}}>If the company sold, please be sure to add this as a
                        completed transaction
                        <p style={{fontSize: '16px', fontWeight: 100}}>
                            {this.context.user_type_id === 3 ? 'Completed transactions associated with your profile help us match you with buyers and sellers as they search on our platform. The more completed transactions you have, the more we can match you. Help us help you at no cost to you.' :
                                <><br/>Did you get your deal sold with BizNexus? If so, please let us know!! We'd love
                                    to speak directly with you and hear how it went, and share your success with our
                                    community (super helpful for a startup like ours trying to establish some bona fides
                                    in an industry full of monster incumbents...
                                    <p className="text-center">
                                        <a rel="noopener noreferrer" className="btn btn-success" target="_blank"
                                           href="https://calendly.com/biznexus/video-testimonial">Schedule a Video
                                            Testimonial Now</a>
                                    </p></>}
                        </p>

                    </ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label style={{paddingTop: 0}} htmlFor="y1" md={4}>Select Buyer Type:</Label>
                            <Col md={7}>
                                <InputGroup>
                                    <Select 
                                            classNamePrefix="Select"
                                            classNames={{
                                                control: (state) =>
                                                    state.isFocused ? 'Select-control' : 'Select-control',
                                            }}
                                        name="professionlist"
                                        id="professionlist"
                                        isSearchable={false}
                                        isClearable={false}
                                        className="w-100"
                                        onChange={this.handleChangeCompletedSelect}
                                        options={this.state.professionlist}
                                        value={this.state.selectedCompletedOption}
                                        ref="professionlist"
                                        style={{backgroundColor: 'white'}}
                                    />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label style={{paddingTop: 0}} htmlFor="y1" md={4}>Buyer Company Name:</Label>
                            <Col md={7}>
                                <InputGroup>
                                    <Input type="text" name="seller_company_name" id="y1"
                                           onChange={this.handleChangelisting.bind(this, "seller_company_name")}
                                           value={this.state.fields["seller_company_name"]}/>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label style={{paddingTop: 0}} htmlFor="y1" md={4}>Company Sale price:</Label>
                            <Col md={7}>
                                <InputGroup>
                                    <InputGroupText>
                                        $
                                    </InputGroupText>
                                    <Input type="text" name="revenue" id="y1"
                                           onChange={this.handleChangelisting.bind(this, "price")}
                                           value={this.state.fields["price"]}/>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label style={{paddingTop: 0}} htmlFor="y2" md={4}>Approximately how long was this deal on
                                the market? (months)</Label>
                            <Col md={7}>
                                <Input
                                    name="y2"
                                    id="y2"
                                    type="number"
                                    min={'0'}
                                    onChange={e => this.handleFormChange(e, {
                                        name: "deal_on_market",
                                        value: e.target.value
                                    })}
                                    value={this.state.deal_on_market}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="seller-financing">
                            <Label style={{paddingTop: 0}} htmlFor="financing" md={4}>Was there seller financing in this
                                deal?</Label>
                            <Col md={7}>
                                <InputGroup>
                                    <form className="form-inline ">
                                        <div className="text-center seller-financing__radio"><Label
                                            htmlFor="y4">Yes</Label>
                                            <Input type="radio"
                                                   id="y4"
                                                   checked={this.state.seller_finance_deal === true}
                                                   onChange={e => this.handleFormChange(e, {
                                                       name: "seller_finance_deal",
                                                       value: true
                                                   })}
                                            /></div>
                                        <div style={{marginLeft: '20px'}}
                                             className="text-center seller-financing__radio"><Label
                                            htmlFor="y44">No</Label>
                                            <Input type="radio"
                                                   id="y44"
                                                   checked={!this.state.seller_finance_deal}
                                                   onChange={e => this.handleFormChange(e, {
                                                       name: "seller_finance_deal",
                                                       value: false
                                                   })}
                                            /></div>
                                    </form>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="delete-modal__footer">
                        <button className="btn" onClick={this.Deletelisttoggle}>Cancel</button>

                        <button style={{border: '1px solid gray', marginLeft: 'auto'}}
                                className="btn btn-outline-primary-gray" onClick={this.handleRemoveListingClick}
                                data-itemID={this.state.itemID}>Delete Only
                        </button>
                        <Button color="success" onClick={this.moveToCompleted}>Move to completed</Button>
                    </ModalFooter>
                </Modal>

                <Col sm="3" className="nav-tabs-list">

                    <p onClick={() => {
                        if (this.state.currentPage !== 1) {
                            this.handlePageChange(1)
                        }
                        this.props.history.push('/user/listings');
                    }}
                       className={`ms-1 ${this.state.activeLeftTab === null ? 'favorite-item-active' : 'favorite-item'} `}
                       style={{cursor: 'pointer', fontSize: 16}}>
                        <i class="fa-regular fa-rectangle-list"></i> My Deals</p>

                    {
                        this.context.user_type_id === USER_TYPE.BROKER &&
                        <p onClick={() => {
                            this.props.history.push('/user/listings/suggested_buyers');
                        }}
                           className={`ms-1 ${this.state.activeLeftTab === 'Leads' ? 'favorite-item-active' : 'favorite-item'} `}
                           style={{cursor: 'pointer', fontSize: 16}}>
                            <i className="fa-solid fa-users"></i> Suggested Buyers
                        </p>
                    }
                    {/*{*/}
                    {/*    this.context.user_type_id !== 3 &&*/}
                    {/*    <p onClick={() => {*/}
                    {/*        this.props.history.push('/user/listings/suggested_brokers');*/}
                    {/*    }}*/}
                    {/*       className={`ms-1 ${this.state.activeLeftTab === 'Brokers' ? 'favorite-item-active' : 'favorite-item'} `}*/}
                    {/*       style={{cursor: 'pointer', fontSize: 16}}>*/}
                    {/*        <i className="fa-solid fa-users"></i> Suggested M&A Advisors*/}
                    {/*    </p>*/}
                    {/*}*/}

                    <p onClick={() => {
                        this.props.history.push('/user/listings/archive');
                    }}
                       className={`ms-1 ${this.state.activeLeftTab === 'ArchivedListings' ? 'favorite-item-active' : 'favorite-item'} `}
                       style={{cursor: 'pointer', fontSize: 16}}>
                        <i class="fas fa-archive"></i> {this.context.user_type_id === 3 ? 'Archived Deals' : 'Archived Deals'} {this.state.archivedDeals.length > 0 ? `(${this.state.archivedDeals.length})` : ``}
                    </p>

                    <p onClick={() => {
                        this.props.history.push('/user/listings/completed');
                    }}
                       className={`ms-1 ${this.state.activeLeftTab === 'CompletedTransactions' ? 'favorite-item-active' : 'favorite-item'} `}
                       style={{cursor: 'pointer', fontSize: 16}}>
                        <i class="fas fa-check-double"/> Completed Transactions</p>

                    {/*{this.context.user_type_id === 3 && <p onClick={() => {*/}
                    {/*    this.setState({ activeLeftTab: 'EmbedCode' })*/}
                    {/*}} className={`ms-1 ${this.state.activeLeftTab === 'EmbedCode' ? 'favorite-item-active' : 'favorite-item'} `} style={{ cursor: 'pointer', fontSize: 16 }}>*/}
                    {/*<i class="fas fa-code"></i> Embed Code</p>}*/}

                </Col>
                <Col sm="9">

                    {this.state.activeLeftTab === null && <div>
                        {this.context.user_type_id !== 3 &&
                            <div className="mb-3 position-relative">
                                <h1 className="h1 mb-3">BizNexus ExitPrep</h1>
                                <h2 className="h2 mb-3">Where Main Street Meets Wall Street &reg;</h2>
                                <h3 className="h3 mb-3">Create Your Confidential Business Profile Today</h3>
                                <h4 className="h4 mb-3">Get matched with best-fit buyers and advisors.</h4>
                                <p className="mb-4">
                                    Create your anonymized acquisition opportunity and submit it to our team for review.
                                    Your business will remain hidden from our public marketplace, and we’ll reach out to
                                    you directly to
                                    schedule a confidential consultation. From there, we can match you appropriately
                                    however & whenever you see fit.
                                </p>
                                {/*<a className='d-none d-md-block' style={{ position: 'absolute', right: 0 }} href="https://community.biznexus.com/courses/6278321/content" rel="noopener noreferrer" target="_blank">*/}
                                {/*    <img src="/images/fsbo-video.png" width="225px" heigh="225px" alt="" />*/}
                                {/*    <p style={{ width: 225, textAlign: 'center', fontWeight: 400, fontSize: 14 }}>ExitPrep Tutorial</p>*/}
                                {/*</a>*/}
                                <Row>
                                    <Col sm={'12'} className="mb-3" style={{zIndex: 99}}>
                                        <div className="news-card">
                                            <h2 className="h2 mb-3">Post Your Sell-Side Opportunity</h2>
                                            <h2 className="h3" style={{fontWeight: 400}}>1. Create a hidden, incognito
                                                business profile</h2>
                                            <h2 className="h3" style={{fontWeight: 400}}>2. Our team will review your
                                                deal and will reach out to schedule your confidential consultation</h2>
                                            <h2 className="h3" style={{fontWeight: 400}}>3. We’ll flesh out your best
                                                exit options and present you with best-fit acquirers, capital providers
                                                and M&A advisors</h2>
                                            <button style={{height: 39}} className="btn btn-success mt-3"
                                                    onClick={() => {
                                                        this.setState({
                                                            triggerListingModal: false,
                                                            privateTeaser: false
                                                        });
                                                        this.Listingtoggle();
                                                    }}>Submit My Deal Now
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        }

                        {this.context.user_type_id !== 3 && <div className="divider mb-5"/>}
                        <div className="" style={{position: 'relative'}}>
                            <FormGroup className="control-bx">
                                <div className="row">
                                    <div className="form-inline col">
                                        <h1 className="h1 mb-4">
                                            {this.context.user_type_id === 3 ? 'Current Deals for Sale' : 'Current Opportunities'}
                                            {this.context.user_type_id === 3 &&
                                                <button className="btn btn-success d-block create_deal_btn"
                                                        onClick={() => this.triggerListingModal()}>{this.context.user_type_id === 3 ? 'Add New Deal' : 'Create A Deal Opportunity Now'}</button>
                                            }
                                        </h1>
                                    </div>

                                    <div className="col-sm-auto pt-1" style={{fontSize: 13}}>
                                        <div className="form-inline">
                                            <FormGroup className="d-none me-sm-2 d-sm-block">
                                                <Label style={{
                                                    color: this.state.viewMode === 'list' ? 'rgb(56, 144, 218)' : '#5d5d5d',
                                                    cursor: 'pointer',
                                                    borderBottom: this.state.viewMode === 'list' ? '1px solid rgb(56, 144, 218)' : 'none'
                                                }} onClick={() => this.changeViewMode('list')}>
                                                    List View<i className="fas fa-th-list ms-1"></i>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup className="d-none me-sm-2 d-sm-block">
                                                <Label style={{
                                                    color: this.state.viewMode === 'table' ? 'rgb(56, 144, 218)' : '#5d5d5d',
                                                    cursor: 'pointer',
                                                    borderBottom: this.state.viewMode === 'table' ? '1px solid rgb(56, 144, 218)' : 'none'
                                                }} onClick={() => this.changeViewMode('table')}>
                                                    Table View<i className="fas fa-table ms-1"></i>
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !!this.state.listinglist.length &&
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state.currentPage}
                                        itemsCountPerPage={this.state.dealPerPage}
                                        totalItemsCount={this.state.totalCount}
                                        pageRangeDisplayed={3}
                                        onChange={this.handlePageChange}
                                    />
                                }

                                <div className="user-listings-wrapper" style={{position: 'relative'}}>
                                    {
                                        this.state.dealLoading
                                            ? <div className="loader_listings_page" id="loaderlistings">
                                                <div className="spiner"/>
                                            </div>
                                            : <>

                                                {this.state.viewMode === 'table' &&
                                                    <Table bordered hover striped>
                                                        <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Location</th>
                                                            <th>Industry</th>
                                                            <th>Price</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody style={{position: 'relative'}}>
                                                        {this.state.listinglist.map((deal, j) => (
                                                            <tr>
                                                                <td id={deal.id} onClick={this.handleEditListingClick}
                                                                    className="cursor_pointer">{this.truncString(deal.name, 30)}</td>
                                                                <td id={deal.id} onClick={this.handleEditListingClick}
                                                                    className="cursor_pointer">{this.truncString(deal.location_address, 30)}</td>
                                                                <td id={deal.id} onClick={this.handleEditListingClick}
                                                                    className="cursor_pointer">{deal.industries.map(catresult => catresult.industry.name).join(', ')}</td>
                                                                <td id={deal.id} onClick={this.handleEditListingClick}
                                                                    className="cursor_pointer">{(deal.hide_price || !deal.price) ? 'Not Specified' : deal.price}</td>
                                                                <td className="text-center">
                                                                    <i id={deal.id} onClick={this.handleEditListingClick}
                                                                       className="fas fa-edit cursor_pointer"></i>
                                                                    <i id={deal.id} onClick={this.Deletelisttoggle}
                                                                       className="ms-2 fas fa-trash-alt cursor_pointer"></i>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </Table>
                                                }

                                                {this.state.viewMode === 'list' && this.state.listinglist.map((deal, j) => (
                                                    <>
                                                        <div class="business-card mb-4">

                                                            <div class="business-card__image position-relative"
                                                                 style={{minWidth: 243}}>
                                                                <a href={`/business-for-sale/${deal.transaction_slug}`}
                                                                   target="_blank" rel="noopener noreferrer">
                                                                    <img src={(deal.image !== null && deal.image !== '')
                                                                        ? getImageUrl(deal.image, true)
                                                                        : process.env.PUBLIC_URL + '/images/empty.jpg'
                                                                    } alt="" style={{objectFit: 'cover'}}/>
                                                                    <div className="overlay">
                                                                        {deal.industries.map((catresult, key) => {
                                                                            return (
                                                                                <span className="result-category me-2 mb-2"
                                                                                      key={`catresultList-${key}`}>{catresult.industry.name}</span>
                                                                            )
                                                                        })}
                                                                    </div>

                                                                    {<div className=""
                                                                          style={{position: 'absolute', bottom: 0}}>
                                                                        <div style={{
                                                                            borderRadius: '0px 10px',
                                                                            backgroundColor: getPropDealColor(deal)
                                                                        }} class="btn btn-primary w-100">
                                                                            {deal.completed === 3 && 'Under Review'}{deal.completed === 4 && 'Rejected'}{deal.completed === 0 && 'Live'}{deal.completed === 5 ? deal.keywords === 'FSBO' ? 'Draft' : 'Private Teaser' : null}
                                                                        </div>
                                                                    </div>}
                                                                </a>
                                                            </div>

                                                            <div class="business-card__content" style={{width: '100%'}}>
                                                                <div class="business-card__actions" style={{minWidth: 120}}>


                                                                    <UncontrolledDropdown
                                                                        style={{position: 'absolute', right: 10}}>
                                                                        <button style={{color: 'black'}}
                                                                                className="btn btn-sm me-1"
                                                                                onClick={() => this.boostModal(deal.id, deal.name)}>
                                                                            <i className="fas fa-bolt me-1"></i> Boost
                                                                        </button>
                                                                        <DropdownToggle tag={'span'} className={'btn'}>
                                                                            <i className="fas fa-ellipsis-h"
                                                                               style={{fontSize: 24, color: '#212529'}}></i>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className={'dropdown-listing'}>
                                                                            <DropdownItem id={deal.id}
                                                                                          onClick={this.handleEditListingClick}>
                                                                                <i className="fas fa-edit"></i> Edit
                                                                            </DropdownItem>
                                                                            <DropdownItem id={deal.id}
                                                                                          onClick={this.Deletelisttoggle}>
                                                                                <i className="fas fa-trash-alt"></i> Delete
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>


                                                                    <br/>
                                                                    {(deal.completed === 0 || deal.completed === 5) &&
                                                                        <div class="" style={{
                                                                            padding: 10,
                                                                            float: 'right',
                                                                            fontSize: 16
                                                                        }}>
                                                                            <div class="custom-control custom-switch">
                                                                                <FormGroup switch>
                                                                                    <Input
                                                                                        checked={deal.completed === 0}
                                                                                        class="custom-control-input" id={`customSwitch${deal.id}`} type="switch" role="switch" onChange={(e) => this.onChangeVisibility(e.target.checked, deal)}/>
                                                                                    <Label check>
                                                                                        Public Visibility


                                                                                        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`PublicVisibilityPublic`} className="icon tooltip-icon text-secondary">
                                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                                                            <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                        <UncontrolledTooltip placement="bottom" target={`PublicVisibilityPublic`}
                                                                                                             autohide={false}
                                                                                                             innerClassName="areas-tooltip">
                                                                                            {
                                                                                                deal.completed === 0
                                                                                                    ? 'This deal is currently live and viewable on the BizNexus platform. Turn public visibility OFF to make this deal private and remove it from search results.'
                                                                                                    : 'This deal is private. Turn public visibility ON to make this deal live and visible in search results on the BizNexus platform.'
                                                                                            }
                                                                                        </UncontrolledTooltip>
                                                                                    </Label>
                                                                                </FormGroup>
                                                                            </div>
                                                                        </div>}
                                                                </div>
                                                                <h3 class="business-card__title">
                                                                    <a style={{color: '#373c41'}}
                                                                       href={`/business-for-sale/${deal.transaction_slug}`}
                                                                       target="_blank" rel="noopener noreferrer">
                                                                        {this.truncString(deal.name, 33, '...')}
                                                                    </a>
                                                                </h3>

                                                                <p class="business-card__location d-flex"
                                                                   style={{marginBottom: 30}}>
                                                                    {deal.location_address && <><span class="me-1">
                                            <svg class="icon" viewBox="0 0 18 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M9 24C9 24 18 15.471 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 3.55683e-08 6.61305 0 9C0 15.471 9 24 9 24ZM9 13.5C10.1935 13.5 11.3381 13.0259 12.182 12.182C13.0259 11.3381 13.5 10.1935 13.5 9C13.5 7.80653 13.0259 6.66193 12.182 5.81802C11.3381 4.97411 10.1935 4.5 9 4.5C7.80653 4.5 6.66193 4.97411 5.81802 5.81802C4.97411 6.66193 4.5 7.80653 4.5 9C4.5 10.1935 4.97411 11.3381 5.81802 12.182C6.66193 13.0259 7.80653 13.5 9 13.5Z"
                                                      fill="currentColor"></path>
                                            </svg>
                                        </span>
                                                                        <span>{deal.location_address}</span></>}
                                                                </p>
                                                                <p class="business-card__description"
                                                                   style={{minHeight: 50, maxWidth: 600}}>
                                                                    {this.truncString(deal.description, 200, '...')}
                                                                </p>
                                                                <div class="row row-gutter-15 mb-3">
                                                                    <div class="col">
                                                                        <p>
                                                                            <span class="font-weight-800">Price: </span>
                                                                            <p class="text-primary-blue">{(deal.hide_price || !deal.price) ? 'Not Specified' : deal.price}</p>
                                                                        </p>
                                                                    </div>
                                                                    <div class="col">
                                                                        <p>
                                                                            <span class="font-weight-800">Revenue: </span>
                                                                            <p class="text-primary-blue">{deal.revenue || '-'}</p>
                                                                        </p>
                                                                    </div>
                                                                    <div class="col">
                                                                        <p>
                                                                            <span class="font-weight-800">SDE: </span>
                                                                            <p class="text-primary-blue">{deal.cashflow || '-'}</p>
                                                                        </p>
                                                                    </div>
                                                                    <div class="col">
                                                                        <p>
                                                                            <span class="font-weight-800">EBITDA: </span>
                                                                            <p class="text-primary-blue">{deal.ebitda || '-'}</p>
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                                <div class="row row-gutter-15">


                                                                    <div class="col">
                                                                        <span className="d-none d-md-block">Share: </span>

                                                                        <LinkedinShareButton style={{padding: 3}}
                                                                                             url={`${process.env.REACT_APP_ROOT}business-for-sale/${deal.transaction_slug}`}>
                                                                            <LinkedinIcon size={32} round={true}/>
                                                                        </LinkedinShareButton>
                                                                        <FacebookShareButton style={{padding: 3}}
                                                                                             url={`${process.env.REACT_APP_ROOT}business-for-sale/${deal.transaction_slug}`}>
                                                                            <FacebookIcon size={32} round={true}/>
                                                                        </FacebookShareButton>
                                                                        <TwitterShareButton style={{padding: 3}}
                                                                                            url={`${process.env.REACT_APP_ROOT}business-for-sale/${deal.transaction_slug}`}>
                                                                            <TwitterIcon size={32} round={true}/>
                                                                        </TwitterShareButton>

                                                                    </div>
                                                                    <div class="col">
                                                                        <a href={`/business-for-sale/${deal.transaction_slug}`}
                                                                           target="_blank" rel="noopener noreferrer"
                                                                           class="btn btn-primary w-100">View details</a>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                        {
                                                            this.context.user_type_id === USER_TYPE.BROKER &&
                                                            <SuggestedBuyers deal={deal} defaultActive
                                                                             handleClick={() => this.setState({
                                                                                 activeLeftTab: 'Leads',
                                                                                 dealId: deal.id
                                                                             })}/>

                                                        }
                                                        {/*{this.context.user_type_id === USER_TYPE.BUYER && <SuggestedBuyers deal={deal} defaultActive handleClick={() => this.setState({activeLeftTab: 'Brokers', dealId: deal.id})} brokers/>}*/}


                                                    </>
                                                ))}

                                                {this.state.listinglist.length === 0 &&
                                                    <div className="justify-content-center row mb-4">
                                                        <div className="col-xl-12">
                                                            <div className="profile-bx" style={{
                                                                boxShadow: '1px 1px 20px #e1e1e1',
                                                                backgroundColor: '#f8f8f8'
                                                            }}>
                                                                <div className="profile-form mt-4 mb-4">
                                                                    <p className="text-center">
                                                                        <i style={{fontSize: '48px'}}
                                                                           className="fas fa-database"></i>
                                                                    </p>
                                                                    <p className="text-center"
                                                                       style={{fontSize: '15px', fontStyle: 'italic'}}>
                                                                        You don't have listings yet
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>}
                                            </>
                                    }
                                </div>

                                {
                                    !!this.state.listinglist.length &&
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={this.state.currentPage}
                                        itemsCountPerPage={this.state.dealPerPage}
                                        totalItemsCount={this.state.totalCount}
                                        pageRangeDisplayed={3}
                                        onChange={this.handlePageChange}
                                    />
                                }


                                {this.state.privateDeals.length > 0 && <h2 className="h2 mb-3">Private Deals</h2>}
                                {this.state.privateDeals.length > 0 &&
                                    <>
                                        {this.state.privateDeals.map((deal, j) => {
                                            return (
                                                <div class="business-card">
                                                    <div class="business-card__image position-relative"
                                                         style={{minWidth: 266}}>
                                                        <img src={(deal.image !== null && deal.image !== '')
                                                            ? getImageUrl(deal.image, true)
                                                            : process.env.PUBLIC_URL + '/images/empty.jpg'
                                                        } alt="" style={{width: 266, objectFit: 'unset'}}/>
                                                        <div className="overlay">
                                                            {deal.industries.map((catresult, key) => {
                                                                return (
                                                                    <span className="result-category me-2 mb-2"
                                                                          key={`catresultList-${key}`}>{catresult.industry.name}</span>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div class="business-card__content" style={{width: '100%'}}>
                                                        <div class="business-card__actions">


                                                            <button id={deal.id} onClick={this.Deletelisttoggle}
                                                                    className="btn btn-tag" style={{
                                                                marginLeft: 5,
                                                                fontSize: 13,
                                                                float: 'right',
                                                                padding: '15px 15px',
                                                            }}>
                                                                Delete
                                                                <i className="fas fa-trash-alt"
                                                                   style={{marginLeft: 5}}></i>
                                                            </button>
                                                            <button id={deal.id} onClick={this.handleEditListingClick}
                                                                    className="btn btn-tag" style={{
                                                                marginLeft: 5,
                                                                fontSize: 13,
                                                                float: 'right',
                                                                padding: '15px 15px'
                                                            }}>
                                                                Edit
                                                                <i className="far fa-edit" style={{marginLeft: 5}}></i>
                                                            </button>
                                                        </div>
                                                        <h3 class="business-card__title">
                                                            <a style={{color: '#373c41'}}
                                                               href={`/business-for-sale/${deal.transaction_slug}`}
                                                               target="_blank" rel="noopener noreferrer">
                                                                {this.truncString(deal.name, 100, '...')}
                                                            </a>
                                                        </h3>
                                                        {(deal.completed === 0 || deal.completed === 5 || deal.completed === 2) &&
                                                            <div class="" style={{float: 'right', fontSize: 16}}>
                                                                <div class="custom-control custom-switch">
                                                                    <FormGroup switch>
                                                                        <Input
                                                                            checked={deal.completed === 0}
                                                                            class="custom-control-input" id={`customSwitch${deal.id}`} type="switch" role="switch" onChange={(e) => this.onChangeVisibility(e.target.checked, deal)}/>
                                                                        <Label check>
                                                                            Public Visibility

                                                                            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`PublicVisibilityPrivate`} className="icon tooltip-icon text-secondary">
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                                                <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                                            </svg>
                                                                            <UncontrolledTooltip placement="bottom" target={`PublicVisibilityPrivate`}
                                                                                                 autohide={false}
                                                                                                 innerClassName="areas-tooltip">
                                                                                {
                                                                                    deal.completed === 0
                                                                                        ? 'This deal is currently live and viewable on the BizNexus platform. Turn public visibility OFF to make this deal private and remove it from search results.'
                                                                                        : 'This deal is private. Turn public visibility ON to make this deal live and visible in search results on the BizNexus platform.'
                                                                                }
                                                                            </UncontrolledTooltip>
                                                                        </Label>
                                                                    </FormGroup>
                                                                </div>
                                                            </div>}
                                                        {deal.location_address &&
                                                            <p class="business-card__location d-flex">
                                                    <span class="me-1">
                                                        <svg class="icon" viewBox="0 0 18 24" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                  d="M9 24C9 24 18 15.471 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 3.55683e-08 6.61305 0 9C0 15.471 9 24 9 24ZM9 13.5C10.1935 13.5 11.3381 13.0259 12.182 12.182C13.0259 11.3381 13.5 10.1935 13.5 9C13.5 7.80653 13.0259 6.66193 12.182 5.81802C11.3381 4.97411 10.1935 4.5 9 4.5C7.80653 4.5 6.66193 4.97411 5.81802 5.81802C4.97411 6.66193 4.5 7.80653 4.5 9C4.5 10.1935 4.97411 11.3381 5.81802 12.182C6.66193 13.0259 7.80653 13.5 9 13.5Z"
                                                                  fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                                <span>{deal.location_address}</span>
                                                            </p>}
                                                        <p class="business-card__description"
                                                           style={{minHeight: 50, maxWidth: 600}}>
                                                            {this.truncString(deal.description, 200, '...')}
                                                        </p>
                                                        <div class="row row-gutter-15 mb-3">
                                                            <div class="col">
                                                                <p>
                                                                    <span class="font-weight-bolder">Revenue: </span>
                                                                    <p class="text-primary-blue">{deal.revenue || '-'}</p>
                                                                </p>
                                                            </div>
                                                            <div class="col">
                                                                <p>
                                                                    <span class="font-weight-bolder">SDE: </span>
                                                                    <p class="text-primary-blue">{deal.cashflow || '-'}</p>
                                                                </p>
                                                            </div>
                                                            <div class="col">
                                                                <p>
                                                                    <span class="font-weight-bolder">EBITDA: </span>
                                                                    <p class="text-primary-blue">{deal.ebitda || '-'}</p>
                                                                </p>
                                                            </div>

                                                        </div>
                                                        <div class="row row-gutter-15">
                                                            <div class="col">
                                                                <div style={{backgroundColor: '#FFF', fontSize: 16}}
                                                                     class="btn btn-secondary w-100 price_button_no_cursor">Price: {deal.hide_price ? 'Not Specified' : deal.price}</div>
                                                            </div>
                                                            <div class="col">
                                                                <a href={`/business-for-sale/${deal.transaction_slug}`}
                                                                   target="_blank" rel="noopener noreferrer"
                                                                   class="btn btn-primary w-100">View details</a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>)
                                        })
                                        }</>}


                                {/* {this.state.fsbo.length > 0 &&
                            <>
                                {this.state.fsbo.map((deal, j) => {
                                    return (
                                        <div class="business-card">
                                            <div class="business-card__image position-relative" style={{ minWidth: 266 }}>
                                                <img src={(deal.image !== null && deal.image !== '')
                                                            ? getImageUrl(deal.image, true)
                                                            : process.env.PUBLIC_URL + '/images/empty.jpg'
                                                        } alt="" style={{ width: 266, objectFit: 'unset' }} />
                                                <div className="overlay">
                                                    {deal.industries.map((catresult, key) => {
                                                        return (
                                                        <span className="result-category me-2 mb-2" key={`catresultList-${key}`}>{catresult.industry.name}</span>
                                                        )
                                                    })}
                                                </div>    
                                            </div>
                                            <div class="business-card__content" style={{ width: '100%' }}>
                                                <div class="business-card__actions">
                                                    

                                                    <button id={deal.id} onClick={this.Deletelisttoggle} className="btn btn-tag" style={{ marginLeft: 5, fontSize: 13, float: 'right', padding: '15px 15px', }}>
                                                        Delete
                                                        <i className="fas fa-trash-alt" style={{ marginLeft: 5 }}></i>
                                                    </button>
                                                    <button onClick={() => this.boostModal(deal.id, deal.name)} className="btn btn-tag" style={{ marginLeft: 5, fontSize: 13, float: 'right', padding: '15px 15px' }}>
                                                        Boost
                                                        <i className="fas fa-bolt" style={{ marginLeft: 5 }}></i>
                                                    </button>
                                                    <button id={deal.id} onClick={this.handleEditListingClick} className="btn btn-tag" style={{ marginLeft: 5, fontSize: 13, float: 'right', padding: '15px 15px' }}>
                                                        Edit
                                                        <i className="far fa-edit" style={{ marginLeft: 5 }}></i>
                                                    </button>
                                                </div>
                                                <h3 class="business-card__title">
                                                    <a style={{ color: '#373c41' }} href={`/business-for-sale/${deal.transaction_slug}`} target="_blank" rel="noopener noreferrer">
                                                    {this.truncString(deal.name, 100, '...')}
                                                    </a>
                                                </h3>
                                                <span style={{ backgroundColor: 'transparent', border: `1px solid black` }} className={`badge text-dark`}>
                                                    {deal.completed === 3 && 'Under Review'}{deal.completed === 4 && 'Rejected'}{deal.completed === 0 && 'Live'}
                                                </span>
                                                
                                                {deal.location_address && <p class="business-card__location d-flex">
                                                    <span class="me-1">
                                                        <svg class="icon" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 24C9 24 18 15.471 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 3.55683e-08 6.61305 0 9C0 15.471 9 24 9 24ZM9 13.5C10.1935 13.5 11.3381 13.0259 12.182 12.182C13.0259 11.3381 13.5 10.1935 13.5 9C13.5 7.80653 13.0259 6.66193 12.182 5.81802C11.3381 4.97411 10.1935 4.5 9 4.5C7.80653 4.5 6.66193 4.97411 5.81802 5.81802C4.97411 6.66193 4.5 7.80653 4.5 9C4.5 10.1935 4.97411 11.3381 5.81802 12.182C6.66193 13.0259 7.80653 13.5 9 13.5Z" fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                    <span>{deal.location_address}</span>
                                                </p>}
                                                <p class="business-card__description" style={{ minHeight: 50 }}>
                                                {this.truncString(deal.description, 200, '...')}
                                                </p>
                                                <div class="row row-gutter-15 mb-3">
                                                <div class="col">
                                                    <p>
                                                        <span class="font-weight-bolder">Revenue: </span>
                                                        <p class="text-primary-blue">{deal.revenue || '-'}</p>
                                                    </p>
                                                </div>
                                                <div class="col">
                                                    <p>
                                                        <span class="font-weight-bolder">SDE: </span>
                                                        <p class="text-primary-blue">{deal.cashflow || '-'}</p>
                                                    </p>
                                                </div>
                                                <div class="col">
                                                    <p>
                                                        <span class="font-weight-bolder">EBITDA: </span>
                                                        <p class="text-primary-blue">{deal.ebitda || '-'}</p>
                                                    </p>
                                                </div>
                                                    
                                                </div>
                                                <div class="row row-gutter-15">
                                                    <div class="col">
                                                        <div style={{ backgroundColor: '#FFF', fontSize: 16 }} class="btn btn-secondary w-100 price_button_no_cursor">Price: {deal.hide_price ? 'Not Specified' : deal.price}</div>
                                                    </div>
                                                    <div class="col">
                                                        <a href={`/business-for-sale/${deal.transaction_slug}`} target="_blank" rel="noopener noreferrer" class="btn btn-primary w-100">View details</a>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>)})
                            }</>} */}


                                {/* {this.context.user_type_id === 3 && <div>
                            <input id="sBuyers" type="checkbox" checked={this.state.suggestedBuyers} value={this.state.suggestedBuyers} onChange={(e) => this.updateSuggestedBuyers(e.target.checked)} />
                            <label for="sBuyers" style={{ padding: '5px' }}>Show Suggested Buyers</label>
                        </div>} */}


                            </FormGroup>
                        </div>
                        {(this.state.active.length > 0 || this.state.inactive.length > 0) && <div>
                            <Nav tabs style={{paddingTop: '10px'}}>
                                <NavItem style={{cursor: 'pointer', width: '50%'}}>
                                    <NavLink className={classnames({active: this.state.activeTab === 1})}
                                             onClick={() => {
                                                 this.setState({activeTab: 1})
                                             }}
                                             style={{textTransform: 'capitalize'}}>
                                        Boosted Listings
                                    </NavLink>
                                </NavItem>
                                <NavItem style={{cursor: 'pointer', width: '50%'}}>
                                    <NavLink className={classnames({active: this.state.activeTab === 2})}
                                             onClick={() => {
                                                 this.setState({activeTab: 2})
                                             }}
                                             style={{textTransform: 'capitalize'}}>
                                        Past Orders
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId={1}>
                                    <Row style={{paddingTop: '10px'}}>
                                        <Col sm="12">
                                            <Table hover responsive>
                                                <thead>
                                                <tr>
                                                    <th style={{padding: '.75rem'}}>#</th>
                                                    <th style={{padding: '.75rem'}}>Listing</th>
                                                    <th style={{padding: '.75rem'}}>Price</th>
                                                    <th style={{padding: '.75rem'}}>Purchase date</th>
                                                    <th style={{padding: '.75rem'}}>Clicks</th>
                                                    <th style={{padding: '.75rem'}}>Views</th>
                                                    <th style={{padding: '.75rem'}}>CTR</th>
                                                    <th style={{padding: '.75rem'}}>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.active.map((s, i) => (
                                                    <tr>
                                                        <td>{i + 1}</td>
                                                        <td>{s.transaction.name}</td>
                                                        <td>{s.stripe_subscription_id !== '-' ? `${this.getAmount(s.stripe_plan_id)}/mo` : 'Free access'}</td>
                                                        <td>{moment(s.feature_start).format('LL')}</td>
                                                        <td>{s.clicks.length}</td>
                                                        <td>{s.views.length}</td>
                                                        <td>{s.views.length > 0 ? (s.clicks.length / s.views.length * 100).toFixed(2) : '0'}%</td>
                                                        <td><Button onClick={() => this.unsubscribe(s.id)}
                                                                    size="sm">Unsubscribe</Button></td>
                                                    </tr>
                                                ))}

                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={2}>
                                    <Row style={{paddingTop: '10px'}}>
                                        <Col sm="12">
                                            <Table hover responsive>
                                                <thead>
                                                <tr>
                                                    <th style={{padding: '.75rem'}}>#</th>
                                                    <th style={{padding: '.75rem'}}>Listing</th>
                                                    <th style={{padding: '.75rem'}}>Price</th>

                                                    <th style={{padding: '.75rem'}}>Clicks</th>
                                                    <th style={{padding: '.75rem'}}>Views</th>
                                                    <th style={{padding: '.75rem'}}>CTR</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.inactive.map((s, i) => (
                                                    <tr>
                                                        <td>{i + 1}</td>
                                                        <td>{s.transaction.name}</td>
                                                        <td>{s.stripe_subscription_id !== '-' ? `${this.getAmount(s.stripe_plan_id)}/mo` : 'Free access'}</td>
                                                        <td>{s.clicks.length}</td>
                                                        <td>{s.views.length}</td>
                                                        <td>{(isNaN(s.clicks.length / s.views.length * 100) ? 0 : s.clicks.length / s.views.length * 100).toFixed(2)}%</td>
                                                    </tr>
                                                ))}

                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent></div>}


                    </div>}

                    <Modal isOpen={this.state.needApproveModal}
                           toggle={() => this.setState({needApproveModal: !this.state.needApproveModal})}
                           className="modal-dialog modal-featured" keyboard={true} backdrop="static">
                        <ModalBody>
                            <div className="advertise-page">
                                <section class="">
                                    <div class="cta-banner" style={{boxShadow: 'none',}}>
                                        <div class="cta-banner__image">
                                            <i style={{fontSize: 130, color: '#3890da'}} class="far fa-clock"></i>
                                        </div>
                                        <div class="cta-banner__content">
                                            <h2 class="cta-banner__content-title">Your Account Is Under Review</h2>
                                            <p class="cta-banner__content-description" style={{fontSize: 13}}>It's not
                                                you, it's us. We review all new platform applicants within 24 hours of
                                                signup. During that time, you may have limited access to key features
                                                while we confirm you're not a malicious bot or bad actor. We appreciate
                                                your patience, and if you have any questions please reach out to our
                                                team at <a href="mailto:help@biznexus.com">help@biznexus.com</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary"
                                    onClick={() => this.setState({needApproveModal: !this.state.needApproveModal})}>Ok</Button>
                        </ModalFooter>
                    </Modal>


                    <Modal size={'lg'} isOpen={this.state.boostModalOpen}
                           toggle={() => this.setState({boostModalOpen: !this.state.boostModalOpen})}
                           className="modal-dialog modal-featured" keyboard={false} backdrop="static">
                        <FeaturedModal
                            listingName={this.state.boostModalName}
                            listingId={this.state.boostModalId}
                            onClose={() => this.setState({boostModalOpen: false})}
                        />
                    </Modal>

                    <Modal className="modal-dialog modal-featured" size={'lg'} isOpen={this.state.privateDealModal}
                           toggle={() => this.setState({privateDealModal: !this.state.privateDealModal})}
                           keyboard={false} backdrop="static">
                        <ModalHeader>About adding a private deal to the BizNexus platform</ModalHeader>
                        <ModalBody style={{padding: '30px'}}>
                            <p>
                                BizNexus now allows intermediaries to privately list lower & middle market deals that
                                are better suited to coordinated, auction-based deal flow. We offer multiple solutions
                                to help not only match, but proactively market these types of deals to appropriate
                                individual, strategic and financial buyers approved by you as the seller’s intermediary.
                            </p>
                            <p>
                                1. Mark your listing as private so it will not be added to our viewable, public
                                marketplace.
                            </p>
                            <p>
                                2. Schedule your consultation with a BizNexus concierge to determine next-steps.
                                <p>
                                </p>
                                3. Depending on your goals, we will create a virtual data room specifically for your
                                deal.
                            </p>
                            <p>
                                4. We will match you with qualified buyers focused on acquisitions that match your
                                deal’s specific criteria.
                            </p>
                            <p>
                                5. You have final approval and 100% control over who you choose to send your CIM to or
                                let into your virtual data room.
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <p style={{textAlign: 'center'}}>Add this deal as a private listing now and choose who you
                                want to show it to</p>
                            <Button color="success" onClick={this.makePrivateDeal}>Add Private Deal Now</Button>
                            <Button color="primary"
                                    onClick={() => this.setState({privateDealModal: !this.state.privateDealModal})}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                    <Modal className="modal-dialog modal-featured" size={'lg'} isOpen={this.state.privateDealModalOk}
                           toggle={() => this.setState({privateDealModalOk: !this.state.privateDealModalOk})}>
                        {/* <ModalHeader toggle={() => this.setState({ privateDealModalOk: !this.state.privateDealModalOk })}></ModalHeader> */}

                        <ModalBody style={{padding: '30px'}}>
                            <h1 class="w-100 text-center">All set!</h1>
                            <p>Your deal has been added as a private deal on BizNexus and is not openly searchable on
                                our platform. Nobody has access to the public URL unless you choose to share it with
                                them, and users will have to be logged in to view the private deal and submit a request
                                for NDA & more information.
                            </p>
                            <p>Two things happen from here:</p>

                            <p>1. You’ll see suggested buyers to reach out to on your listing mgmt page in BizNexus.</p>

                            <p>2. You’ll have the opportunity to schedule a consultation with one of our deal
                                origination specialists if you’d like us to put together a curated list of strategic
                                buyers to approach.</p>
                            <div className="text-center" style={{paddingTop: '35px'}}>
                                <a rel="noopener noreferrer" className="btn btn-success" style={{textAlign: 'center'}}
                                   target="_blank" href="https://calendly.com/biznexus/private-marketplace">Schedule My
                                    Consultation Now</a>
                            </div>
                        </ModalBody>
                    </Modal>


                    <Modal className="modal-dialog modal-featured" isOpen={this.state.triggerListingModal}
                           toggle={() => this.setState({triggerListingModal: !this.state.triggerListingModal})}>
                        <ModalHeader
                            toggle={() => this.setState({triggerListingModal: !this.state.triggerListingModal})}>Add New
                            Deal</ModalHeader>

                        <ModalBody>
                            {this.state.avatarNotFound && <div className="alert alert-warning" role="alert">
                                Please, make sure you have your profile image and About section completed
                            </div>}
                            <Row>
                                <Col sm={'6'} style={{zIndex: 99, padding: 5}} className="text-center">
                                    <button className="btn btn-success mt-3" onClick={() => {
                                        this.setState({triggerListingModal: false, privateTeaser: false});
                                        this.Listingtoggle();
                                    }}>Post a Public Listing
                                    </button>
                                </Col>
                                <div className="vertical-divider" style={{height: 70}}>
                                    <div className="center-element">OR</div>
                                </div>
                                <Col sm={'6'} style={{zIndex: 1}} className="text-center" style={{padding: 5, zIndex: 99}}>
                                    <button className="btn btn-success mt-3 " onClick={() => {
                                        this.setState({triggerListingModal: false, privateTeaser: true});
                                        this.Listingtoggle();
                                    }}>Create a Private Teaser
                                    </button>
                                </Col>

                            </Row>
                        </ModalBody>
                    </Modal>


                    {this.state.activeLeftTab === 'ArchivedListings' && <>
                        <h1 className="h1 mb-3">Archived Deals</h1>
                        {this.state.archivedDeals.length === 0 && <div className="justify-content-center row mb-4">
                            <div className="col-xl-12">
                                <div className="profile-bx"
                                     style={{boxShadow: '1px 1px 20px #e1e1e1', backgroundColor: '#f8f8f8'}}>
                                    <div className="profile-form mt-4 mb-4">
                                        <p className="text-center">
                                            <i style={{fontSize: '48px'}} className="fas fa-archive"></i>
                                        </p>
                                        <p className="text-center" style={{fontSize: '15px', fontStyle: 'italic'}}>
                                            You have no archived listings
                                            <br/><br/>
                                            180 days old listings on BizNexus automatically archive unless you update
                                            them, archive or delete them
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {this.state.archivedDeals.map((deal, j) => (
                            <>
                                <div class="business-card mb-4">

                                    <div class="business-card__image position-relative" style={{minWidth: 243}}>
                                        <a href={`/business-for-sale/${deal.transaction_slug}`} target="_blank"
                                           rel="noopener noreferrer">
                                            <img src={(deal.image !== null && deal.image !== '')
                                                ? getImageUrl(deal.image, true)
                                                : process.env.PUBLIC_URL + '/images/empty.jpg'
                                            } alt="" style={{objectFit: 'cover'}}/>
                                            <div className="overlay">
                                                {deal.industries.map((catresult, key) => {
                                                    return (
                                                        <span className="result-category me-2 mb-2"
                                                              key={`catresultList-${key}`}>{catresult.industry.name}</span>
                                                    )
                                                })}
                                            </div>

                                            {deal.keywords === 'FSBO' &&
                                                <div className="" style={{position: 'absolute', bottom: 0}}>
                                                    <div style={{
                                                        borderRadius: 0,
                                                        backgroundColor: getPropDealColor(deal)
                                                    }} class="btn btn-primary w-100">
                                                        {deal.completed === 3 && 'Under Review'}{deal.completed === 4 && 'Rejected'}{deal.completed === 0 && 'Live'}{deal.completed === 5 && 'Draft'}
                                                    </div>
                                                </div>}
                                        </a>
                                    </div>

                                    <div class="business-card__content" style={{width: '100%'}}>
                                        <div class="business-card__actions">


                                            <button id={deal.id} onClick={this.Deletelisttoggle} className="btn btn-tag"
                                                    style={{
                                                        marginLeft: 5,
                                                        fontSize: 13,
                                                        float: 'right',
                                                        padding: '15px 15px',
                                                    }}>
                                                Delete
                                                <i className="fas fa-trash-alt" style={{marginLeft: 5}}></i>
                                            </button>
                                            <button onClick={() => this.boostModal(deal.id, deal.name)}
                                                    className="btn btn-tag" style={{
                                                marginLeft: 5,
                                                fontSize: 13,
                                                float: 'right',
                                                padding: '15px 15px'
                                            }}>
                                                Boost
                                                <i className="fas fa-bolt" style={{marginLeft: 5}}></i>
                                            </button>
                                            <button id={deal.id} onClick={this.handleEditListingClick}
                                                    className="btn btn-tag" style={{
                                                marginLeft: 5,
                                                fontSize: 13,
                                                float: 'right',
                                                padding: '15px 15px'
                                            }}>
                                                Edit
                                                <i className="far fa-edit" style={{marginLeft: 5}}></i>
                                            </button>
                                            <br/>
                                        </div>
                                        <h3 class="business-card__title">
                                            <a style={{color: '#373c41'}}
                                               href={`/business-for-sale/${deal.transaction_slug}`} target="_blank"
                                               rel="noopener noreferrer">
                                                {this.truncString(deal.name, 40, '...')}
                                            </a>
                                        </h3>
                                        {((deal.completed === 0 || deal.completed === 5) && this.context.user_type_id !== 3) &&
                                            <div class="" style={{padding: 10, float: 'right', fontSize: 16}}>
                                                <div class="custom-control custom-switch">
                                                    <FormGroup switch>
                                                        <Input
                                                            checked={deal.completed === 0}
                                                            class="custom-control-input" id={`customSwitch${deal.id}`} type="switch" role="switch" onChange={(e) => this.onChangeVisibility(e.target.checked, deal)}/>
                                                        <Label check>
                                                            Public Visibility
                                                            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" id={`PublicVisibilityPublic1`} className="icon tooltip-icon text-secondary">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"></path>
                                                                <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"></path>
                                                            </svg>
                                                            <UncontrolledTooltip placement="bottom" target={`PublicVisibilityPublic1`}
                                                                                 autohide={false}
                                                                                 innerClassName="areas-tooltip">
                                                                {
                                                                    deal.completed === 0
                                                                        ? 'This deal is currently live and viewable on the BizNexus platform. Turn public visibility OFF to make this deal private and remove it from search results.'
                                                                        : 'This deal is private. Turn public visibility ON to make this deal live and visible in search results on the BizNexus platform.'
                                                                }
                                                            </UncontrolledTooltip>
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                            </div>}

                                        {deal.location_address && <p class="business-card__location d-flex">
                                        <span class="me-1">
                                            <svg class="icon" viewBox="0 0 18 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M9 24C9 24 18 15.471 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 3.55683e-08 6.61305 0 9C0 15.471 9 24 9 24ZM9 13.5C10.1935 13.5 11.3381 13.0259 12.182 12.182C13.0259 11.3381 13.5 10.1935 13.5 9C13.5 7.80653 13.0259 6.66193 12.182 5.81802C11.3381 4.97411 10.1935 4.5 9 4.5C7.80653 4.5 6.66193 4.97411 5.81802 5.81802C4.97411 6.66193 4.5 7.80653 4.5 9C4.5 10.1935 4.97411 11.3381 5.81802 12.182C6.66193 13.0259 7.80653 13.5 9 13.5Z"
                                                      fill="currentColor"></path>
                                            </svg>
                                        </span>
                                            <span>{deal.location_address}</span>
                                        </p>}
                                        <p class="business-card__description" style={{minHeight: 50, maxWidth: 600}}>
                                            {this.truncString(deal.description, 200, '...')}
                                        </p>
                                        <div class="row row-gutter-15 mb-3">
                                            <div class="col">
                                                <p>
                                                    <span class="font-weight-bolder">Price: </span>
                                                    <p class="text-primary-blue">{(deal.hide_price || !deal.price) ? 'Not Specified' : deal.price}</p>
                                                </p>
                                            </div>
                                            <div class="col">
                                                <p>
                                                    <span class="font-weight-bolder">Revenue: </span>
                                                    <p class="text-primary-blue">{deal.revenue || '-'}</p>
                                                </p>
                                            </div>
                                            <div class="col">
                                                <p>
                                                    <span class="font-weight-bolder">SDE: </span>
                                                    <p class="text-primary-blue">{deal.cashflow || '-'}</p>
                                                </p>
                                            </div>
                                            <div class="col">
                                                <p>
                                                    <span class="font-weight-bolder">EBITDA: </span>
                                                    <p class="text-primary-blue">{deal.ebitda || '-'}</p>
                                                </p>
                                            </div>

                                        </div>
                                        <div class="row row-gutter-15">


                                            <div class="col">

                                                <LinkedinShareButton style={{padding: 3}}
                                                                     url={`${process.env.REACT_APP_ROOT}business-for-sale/${deal.transaction_slug}`}>
                                                    <LinkedinIcon size={32} round={true}/>
                                                </LinkedinShareButton>
                                                <FacebookShareButton style={{padding: 3}}
                                                                     url={`${process.env.REACT_APP_ROOT}business-for-sale/${deal.transaction_slug}`}>
                                                    <FacebookIcon size={32} round={true}/>
                                                </FacebookShareButton>
                                                <TwitterShareButton style={{padding: 3}}
                                                                    url={`${process.env.REACT_APP_ROOT}business-for-sale/${deal.transaction_slug}`}>
                                                    <TwitterIcon size={32} round={true}/>
                                                </TwitterShareButton>

                                            </div>
                                            <div class="col">
                                                <div onClick={() => this.renewDeal(deal.id)}
                                                     class="btn btn-primary w-100">Renew Deal
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </>
                        ))}
                    </>}

                    {this.state.activeLeftTab === 'EmbedCode' &&
                        <EmbedListingsEditProfile userId={this.state.header.id}/>}

                    {this.state.activeLeftTab === 'CompletedTransactions' &&
                        <CompletedTransactions userId={this.props.userId}/>}

                    {this.state.activeLeftTab === 'Leads' && <>
                        {
                            <MyLeads premium={this.state.header.premium} userId={this.props.userId}
                                           preselectedId={this.state.dealId?.toString()}/>
                        }
                    </>}

                    {this.state.activeLeftTab === 'Brokers' && this.state.listinglistLoaded &&
                        <MyLeads premium={this.state.header.premium} userId={this.props.userId}
                                 deals={this.state.listinglist} preselectedId={this.state.dealId?.toString()} brokers/>}
                    <Modal isOpen={this.state.eventModal} toggle={() => this.setState({eventModal: !this.state.eventModal})}
                           className="modal-dialog modal-featured modal-dialog-centered" keyboard={true} backdrop="static">
                        <ModalHeader toggle={() => this.setState({eventModal: !this.state.eventModal})}>

                        </ModalHeader>
                        <ModalBody>
                            <div className="container text-center" style={{position: 'relative', top: 18}}>
                                <h1 className="h1">{this.state.eventHeader}</h1>
                                <div style={{fontSize: 18}}>{this.state.eventMessage}</div>
                            </div>
                            <ModalFooter style={{paddingTop: '15px', borderTop: 'none'}}>
                                <Button color="primary" onClick={() => this.setState({eventModal: false})}>Close</Button>
                            </ModalFooter>
                        </ModalBody>
                    </Modal>


                </Col></Row>
        );
    }
}

export default withRouter(SaleListing);
